import React from 'react';
import { Root, BasicHeaders } from './ApiUrl.js';
import Cross01 from './svg/Cross01.js';
import { ListImgs, TxtConverted } from './CommonComponent.js';

var url = Root + '/wp-json/wp/v2/youtube/';

class ComponentYoutubeDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //state初期化
      id: "",
      title: "",
      img: "",
      txt: "",
      img_pc: "",
      img_sp: "",
      embed: "",
      pageShow: false,
    };
    // console.log(this.props)
  }

  fetchDetailData(){
    this.setState({
      pageShow: false,
    })
    var params = this.props.match.params;
    if(params.slug===undefined){
      return null;
    }
    else{
      fetch(url+'?slug='+params.slug,{
      method:'GET',
      headers: BasicHeaders,
    })
      .then(response => {
          return response.json();
      })
      .then(json => {
        // console.log(json[0]);
        this.setState({
          id: json[0].id,
          title: json[0].title.rendered,
          embed: json[0].custom.embed,
          txt: json[0].custom.txt,
          img_pc: json[0].custom_img_pc,
          img_sp: json[0].custom_img_sp,
        },function(){
          this.setState({
            pageShow: true,
          })
        });
      });
    }
  }

  componentDidMount() {
    this.fetchDetailData();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.url !== this.props.match.url) {
      // console.log("componentDidUpdate");
      this.fetchDetailData();
    }
  }

  render() {
    // console.log(this.state.txt[this.state.txt.length-1]);
    return (
          <div className="S_lo05 S_lo05_ver01" data-show={this.state.pageShow}>
            <div className="S_lo05__wrap04">
              <div className="S_modal__btn01" onClick={() => this.props.history.goBack()}>
                <Cross01 />
              </div>
            </div>
            {this.state.embed &&
              <div className="S_lo05__wrap05">
                <div
                  className="S_iframe01"
                  dangerouslySetInnerHTML={{ __html: this.state.embed }}
                />
              </div>
            }
            {this.state.txt &&
              <p className="S_lo05__txt05" dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.txt[this.state.txt.length-1]) }} />
            }
            {this.state.img_pc &&
              <ListImgs
                imgs={this.state.img_pc}
                className={"S_lo05__list01 u_d_none_SP"}
              />
            }
            {this.state.img_sp &&
              <ListImgs
                imgs={this.state.img_sp}
                className={"S_lo05__list01 u_d_none_PC"}
              />
            }
          </div>
    );
  }
}

export default ComponentYoutubeDetail;