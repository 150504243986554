import React from 'react';
import { Route, Switch } from "react-router-dom";
import ComponentProduceDetail from './ComponentProduceDetail.js';
import { Root, BasicHeaders } from './ApiUrl.js';
import { List02, TxtConverted, Modal01, Nav01 } from './CommonComponent.js';

var archiveUrl = Root + '/wp-json/wp/v2/produce/?per_page=100';
var pageUrl = Root + '/wp-json/wp/v2/pages/115?_fields=custom';

class PageProduce extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //state初期化
      pageDates: {},
      itemDates: {},
      about: "",
      pageDatesLoad: false,
      itemDatesLoad: false,
      pageShow: false,
    };
    this.pageSlug = 'produce';
  }

  fetchPage(){
    fetch(pageUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        pageDates: json,
        about: json.custom.about,
      },function(){
        this.setState({
          pageDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  fetchItem(){
    fetch(archiveUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        itemDates: json,
      },function(){
        this.setState({
          itemDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  componentDidMount() {
    this.fetchPage();
    this.fetchItem();
  }

  render() {
    return (
          <Modal01
            pageShow = {this.state.pageShow}
            area01={
              <Nav01 
                ttl={"Produce"}
                link={[
                        {
                          href: "about",
                          name: "About"
                        },
                        {
                          href: "projects",
                          name: "Projects"
                        },
                      ]}
              />
            }
            area02={
              <>
                <div id="about" className="S_lo06 S_section01">
                  <h3 className="S_heading03">　</h3>
                  <p
                    className="S_lo06__txt01"
                    dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.about) }}
                  />
                </div>
                <div id="projects" className="S_lo01 S_section01">
                  <h3 className="S_heading03">Projects</h3>
                  <div className="S_lo01__wrap01 S_lo01__wrap01_ver01">
                    <div className="S_lo01__wrap02">
                      <div className="S_lo01__wrap05">
                        <List02 itemDates={this.state.itemDates} pageSlug={this.pageSlug} />
                      </div>
                    </div>
                    <div className="S_lo01__wrap03">
                      <Switch>
                        <Route path={"/"+this.pageSlug+"/:slug/"} component={ComponentProduceDetail} />
                      </Switch>
                    </div>
                  </div>
                </div>
              </>
            }
          />
    );
  }
}

export default PageProduce;