import React from 'react';
import { gsap } from 'gsap'
import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

class Orb01 extends React.Component {
  constructor(props){
    super(props);
    this.tl01 = new TimelineLite();
  }

  componentDidMount() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
    this.tl01
    .to("#orb01", {
      opacity: 0.5,
      motionPath:{
        path: "#circle06",
        align: "#circle06",
        autoRotate: -112,
        alignOrigin: [0.5, 0.5],
        start: 0.06,
        end: 0.06,
      }
    }) 
    .pause("#orb01", {
    })
    .to("#orb01", {
      opacity: 1,
      duration: 0.5,
    })
    .to("#orb01", {
      duration: 40, 
      repeat: -1,
      yoyo: false,
      ease: "none",
      motionPath:{
        path: "#circle06",
        align: "#circle06",
        autoRotate: -112,
        alignOrigin: [0.5, 0.5],
        start: 0.06,
        end: 1.06,
      }
    });
  }

  componentDidUpdate() {
    // console.log(this.props)
    if(this.props.orb01){
      this.tl01.play()
    }else(
      this.tl01.pause()
    )
  }

  componentWillUnmount() {
    // console.log("unmount");
    // this.props.display(false);
  }

  render() {
  return (
    <svg id="orb01" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="226.053" height="226.053" viewBox="0 0 226.053 226.053">
      <defs>
        <clipPath id="clip-path03">
          <circle id="楕円形_2523" data-name="楕円形 2523" cx="113.027" cy="113.027" r="113.027" transform="translate(904.056 -3165.932)" fill="none"/>
        </clipPath>
      </defs>
      <g id="グループ_394" data-name="グループ 394" transform="translate(-904.056 3165.932)">
        <g id="グループ_393" data-name="グループ 393" clipPath="url(#clip-path03)">
          <g id="グループ_392" data-name="グループ 392">
            <g id="グループ_278" data-name="グループ 278">
              <circle id="楕円形_2409" data-name="楕円形 2409" cx="89" cy="89" r="89" transform="translate(521.202 -3307.203)" fill="#1a1311" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_279" data-name="グループ 279">
              <circle id="楕円形_2410" data-name="楕円形 2410" cx="90.78" cy="90.78" r="90.78" transform="translate(519.422 -3308.983)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_280" data-name="グループ 280">
              <circle id="楕円形_2411" data-name="楕円形 2411" cx="92.596" cy="92.596" r="92.596" transform="translate(517.607 -3310.798)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_281" data-name="グループ 281">
              <circle id="楕円形_2412" data-name="楕円形 2412" cx="94.448" cy="94.448" r="94.448" transform="translate(515.755 -3312.65)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_282" data-name="グループ 282">
              <circle id="楕円形_2413" data-name="楕円形 2413" cx="96.336" cy="96.336" r="96.336" transform="translate(513.866 -3314.539)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_283" data-name="グループ 283">
              <circle id="楕円形_2414" data-name="楕円形 2414" cx="98.263" cy="98.263" r="98.263" transform="translate(511.939 -3316.466)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_284" data-name="グループ 284">
              <circle id="楕円形_2415" data-name="楕円形 2415" cx="100.228" cy="100.228" r="100.228" transform="translate(509.974 -3318.431)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_285" data-name="グループ 285">
              <circle id="楕円形_2416" data-name="楕円形 2416" cx="102.233" cy="102.233" r="102.233" transform="translate(507.969 -3320.436)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_286" data-name="グループ 286">
              <circle id="楕円形_2417" data-name="楕円形 2417" cx="104.278" cy="104.278" r="104.278" transform="translate(505.924 -3322.48)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_287" data-name="グループ 287">
              <circle id="楕円形_2418" data-name="楕円形 2418" cx="106.363" cy="106.363" r="106.363" transform="translate(503.839 -3324.566)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_288" data-name="グループ 288">
              <circle id="楕円形_2419" data-name="楕円形 2419" cx="108.491" cy="108.491" r="108.491" transform="translate(501.712 -3326.693)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_289" data-name="グループ 289">
              <circle id="楕円形_2420" data-name="楕円形 2420" cx="110.66" cy="110.66" r="110.66" transform="translate(499.542 -3328.863)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_290" data-name="グループ 290">
              <circle id="楕円形_2421" data-name="楕円形 2421" cx="112.874" cy="112.874" r="112.874" transform="translate(497.329 -3331.076)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_291" data-name="グループ 291">
              <circle id="楕円形_2422" data-name="楕円形 2422" cx="115.131" cy="115.131" r="115.131" transform="translate(495.071 -3333.334)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_292" data-name="グループ 292">
              <circle id="楕円形_2423" data-name="楕円形 2423" cx="117.434" cy="117.434" r="117.434" transform="translate(492.768 -3335.636)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_293" data-name="グループ 293">
              <circle id="楕円形_2424" data-name="楕円形 2424" cx="119.782" cy="119.782" r="119.782" transform="translate(490.42 -3337.985)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_294" data-name="グループ 294">
              <circle id="楕円形_2425" data-name="楕円形 2425" cx="122.178" cy="122.178" r="122.178" transform="translate(488.024 -3340.381)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_295" data-name="グループ 295">
              <circle id="楕円形_2426" data-name="楕円形 2426" cx="124.621" cy="124.621" r="124.621" transform="translate(485.581 -3342.824)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_296" data-name="グループ 296">
              <circle id="楕円形_2427" data-name="楕円形 2427" cx="127.114" cy="127.114" r="127.114" transform="translate(483.088 -3345.317)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_297" data-name="グループ 297">
              <circle id="楕円形_2428" data-name="楕円形 2428" cx="129.656" cy="129.656" r="129.656" transform="translate(480.546 -3347.859)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_298" data-name="グループ 298">
              <circle id="楕円形_2429" data-name="楕円形 2429" cx="132.249" cy="132.249" r="132.249" transform="translate(477.953 -3350.452)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_299" data-name="グループ 299">
              <circle id="楕円形_2430" data-name="楕円形 2430" cx="134.894" cy="134.894" r="134.894" transform="translate(475.308 -3353.097)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_300" data-name="グループ 300">
              <circle id="楕円形_2431" data-name="楕円形 2431" cx="137.592" cy="137.592" r="137.592" transform="translate(472.61 -3355.795)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_301" data-name="グループ 301">
              <circle id="楕円形_2432" data-name="楕円形 2432" cx="140.344" cy="140.344" r="140.344" transform="translate(469.858 -3358.547)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_302" data-name="グループ 302">
              <circle id="楕円形_2433" data-name="楕円形 2433" cx="143.151" cy="143.151" r="143.151" transform="translate(467.051 -3361.354)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_303" data-name="グループ 303">
              <circle id="楕円形_2434" data-name="楕円形 2434" cx="146.014" cy="146.014" r="146.014" transform="translate(464.188 -3364.217)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_304" data-name="グループ 304">
              <circle id="楕円形_2435" data-name="楕円形 2435" cx="148.934" cy="148.934" r="148.934" transform="translate(461.268 -3367.137)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_305" data-name="グループ 305">
              <circle id="楕円形_2436" data-name="楕円形 2436" cx="151.913" cy="151.913" r="151.913" transform="translate(458.289 -3370.115)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_306" data-name="グループ 306">
              <circle id="楕円形_2437" data-name="楕円形 2437" cx="154.951" cy="154.951" r="154.951" transform="translate(455.251 -3373.154)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_307" data-name="グループ 307">
              <circle id="楕円形_2438" data-name="楕円形 2438" cx="158.05" cy="158.05" r="158.05" transform="translate(452.152 -3376.253)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_308" data-name="グループ 308">
              <circle id="楕円形_2439" data-name="楕円形 2439" cx="161.211" cy="161.211" r="161.211" transform="translate(448.991 -3379.414)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_309" data-name="グループ 309">
              <circle id="楕円形_2440" data-name="楕円形 2440" cx="164.435" cy="164.435" r="164.435" transform="translate(445.767 -3382.638)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_310" data-name="グループ 310">
              <circle id="楕円形_2441" data-name="楕円形 2441" cx="167.724" cy="167.724" r="167.724" transform="translate(442.478 -3385.927)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_311" data-name="グループ 311">
              <circle id="楕円形_2442" data-name="楕円形 2442" cx="171.079" cy="171.079" r="171.079" transform="translate(439.123 -3389.281)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_312" data-name="グループ 312">
              <circle id="楕円形_2443" data-name="楕円形 2443" cx="174.5" cy="174.5" r="174.5" transform="translate(435.702 -3392.703)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_313" data-name="グループ 313">
              <circle id="楕円形_2444" data-name="楕円形 2444" cx="177.99" cy="177.99" r="177.99" transform="translate(432.212 -3396.193)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_314" data-name="グループ 314">
              <circle id="楕円形_2445" data-name="楕円形 2445" cx="181.55" cy="181.55" r="181.55" transform="translate(428.652 -3399.753)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_315" data-name="グループ 315">
              <circle id="楕円形_2446" data-name="楕円形 2446" cx="185.181" cy="185.181" r="185.181" transform="translate(425.021 -3403.384)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_316" data-name="グループ 316">
              <circle id="楕円形_2447" data-name="楕円形 2447" cx="188.885" cy="188.885" r="188.885" transform="translate(421.317 -3407.087)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_317" data-name="グループ 317">
              <circle id="楕円形_2448" data-name="楕円形 2448" cx="192.662" cy="192.662" r="192.662" transform="translate(417.54 -3410.865)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_318" data-name="グループ 318">
              <circle id="楕円形_2449" data-name="楕円形 2449" cx="196.516" cy="196.516" r="196.516" transform="translate(413.687 -3414.718)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_319" data-name="グループ 319">
              <circle id="楕円形_2450" data-name="楕円形 2450" cx="200.446" cy="200.446" r="200.446" transform="translate(409.756 -3418.648)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_320" data-name="グループ 320">
              <circle id="楕円形_2451" data-name="楕円形 2451" cx="204.455" cy="204.455" r="204.455" transform="translate(405.747 -3422.657)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_321" data-name="グループ 321">
              <circle id="楕円形_2452" data-name="楕円形 2452" cx="208.544" cy="208.544" r="208.544" transform="translate(401.658 -3426.747)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_322" data-name="グループ 322">
              <circle id="楕円形_2453" data-name="楕円形 2453" cx="212.715" cy="212.715" r="212.715" transform="translate(397.487 -3430.917)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_323" data-name="グループ 323">
              <circle id="楕円形_2454" data-name="楕円形 2454" cx="216.969" cy="216.969" r="216.969" transform="translate(393.233 -3435.172)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_324" data-name="グループ 324">
              <circle id="楕円形_2455" data-name="楕円形 2455" cx="221.308" cy="221.308" r="221.308" transform="translate(388.894 -3439.511)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_325" data-name="グループ 325">
              <circle id="楕円形_2456" data-name="楕円形 2456" cx="225.735" cy="225.735" r="225.735" transform="translate(384.467 -3443.937)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_326" data-name="グループ 326">
              <circle id="楕円形_2457" data-name="楕円形 2457" cx="230.249" cy="230.249" r="230.249" transform="translate(379.953 -3448.452)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_327" data-name="グループ 327">
              <circle id="楕円形_2458" data-name="楕円形 2458" cx="234.854" cy="234.854" r="234.854" transform="translate(375.348 -3453.057)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_328" data-name="グループ 328">
              <circle id="楕円形_2459" data-name="楕円形 2459" cx="239.551" cy="239.551" r="239.551" transform="translate(370.651 -3457.754)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_329" data-name="グループ 329">
              <circle id="楕円形_2460" data-name="楕円形 2460" cx="244.342" cy="244.342" r="244.342" transform="translate(365.86 -3462.545)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_330" data-name="グループ 330">
              <circle id="楕円形_2461" data-name="楕円形 2461" cx="249.229" cy="249.229" r="249.229" transform="translate(360.973 -3467.432)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_331" data-name="グループ 331">
              <circle id="楕円形_2462" data-name="楕円形 2462" cx="254.214" cy="254.214" r="254.214" transform="translate(355.988 -3472.417)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_332" data-name="グループ 332">
              <circle id="楕円形_2463" data-name="楕円形 2463" cx="259.298" cy="259.298" r="259.298" transform="translate(350.904 -3477.501)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_333" data-name="グループ 333">
              <circle id="楕円形_2464" data-name="楕円形 2464" cx="264.484" cy="264.484" r="264.484" transform="translate(345.718 -3482.687)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_334" data-name="グループ 334">
              <circle id="楕円形_2465" data-name="楕円形 2465" cx="269.774" cy="269.774" r="269.774" transform="translate(340.428 -3487.976)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_335" data-name="グループ 335">
              <circle id="楕円形_2466" data-name="楕円形 2466" cx="275.169" cy="275.169" r="275.169" transform="translate(335.033 -3493.372)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_336" data-name="グループ 336">
              <circle id="楕円形_2467" data-name="楕円形 2467" cx="280.673" cy="280.673" r="280.673" transform="translate(329.53 -3498.875)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_337" data-name="グループ 337">
              <circle id="楕円形_2468" data-name="楕円形 2468" cx="286.286" cy="286.286" r="286.286" transform="translate(323.916 -3504.489)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_338" data-name="グループ 338">
              <circle id="楕円形_2469" data-name="楕円形 2469" cx="292.012" cy="292.012" r="292.012" transform="translate(318.19 -3510.214)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_339" data-name="グループ 339">
              <circle id="楕円形_2470" data-name="楕円形 2470" cx="297.852" cy="297.852" r="297.852" transform="translate(312.35 -3516.055)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_340" data-name="グループ 340">
              <circle id="楕円形_2471" data-name="楕円形 2471" cx="303.809" cy="303.809" r="303.809" transform="translate(306.393 -3522.012)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_341" data-name="グループ 341">
              <circle id="楕円形_2472" data-name="楕円形 2472" cx="309.885" cy="309.885" r="309.885" transform="translate(300.317 -3528.088)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_342" data-name="グループ 342">
              <circle id="楕円形_2473" data-name="楕円形 2473" cx="316.083" cy="316.083" r="316.083" transform="translate(294.119 -3534.286)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_343" data-name="グループ 343">
              <circle id="楕円形_2474" data-name="楕円形 2474" cx="322.405" cy="322.405" r="322.405" transform="translate(287.798 -3540.607)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_344" data-name="グループ 344">
              <circle id="楕円形_2475" data-name="楕円形 2475" cx="328.853" cy="328.853" r="328.853" transform="translate(281.349 -3547.055)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_345" data-name="グループ 345">
              <circle id="楕円形_2476" data-name="楕円形 2476" cx="335.43" cy="335.43" r="335.43" transform="translate(274.772 -3553.632)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_346" data-name="グループ 346">
              <circle id="楕円形_2477" data-name="楕円形 2477" cx="342.138" cy="342.138" r="342.138" transform="translate(268.064 -3560.341)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_347" data-name="グループ 347">
              <circle id="楕円形_2478" data-name="楕円形 2478" cx="348.981" cy="348.981" r="348.981" transform="translate(261.221 -3567.184)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_348" data-name="グループ 348">
              <circle id="楕円形_2479" data-name="楕円形 2479" cx="355.961" cy="355.961" r="355.961" transform="translate(254.241 -3574.163)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_349" data-name="グループ 349">
              <circle id="楕円形_2480" data-name="楕円形 2480" cx="363.08" cy="363.08" r="363.08" transform="translate(247.122 -3581.282)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_350" data-name="グループ 350">
              <circle id="楕円形_2481" data-name="楕円形 2481" cx="370.341" cy="370.341" r="370.341" transform="translate(239.861 -3588.544)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_351" data-name="グループ 351">
              <circle id="楕円形_2482" data-name="楕円形 2482" cx="377.748" cy="377.748" r="377.748" transform="translate(232.454 -3595.951)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_352" data-name="グループ 352">
              <circle id="楕円形_2483" data-name="楕円形 2483" cx="385.303" cy="385.303" r="385.303" transform="translate(224.899 -3603.506)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_353" data-name="グループ 353">
              <circle id="楕円形_2484" data-name="楕円形 2484" cx="393.009" cy="393.009" r="393.009" transform="translate(217.193 -3611.212)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_354" data-name="グループ 354">
              <circle id="楕円形_2485" data-name="楕円形 2485" cx="400.87" cy="400.87" r="400.87" transform="translate(209.333 -3619.072)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_355" data-name="グループ 355">
              <circle id="楕円形_2486" data-name="楕円形 2486" cx="408.887" cy="408.887" r="408.887" transform="translate(201.315 -3627.09)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_356" data-name="グループ 356">
              <circle id="楕円形_2487" data-name="楕円形 2487" cx="417.065" cy="417.065" r="417.065" transform="translate(193.137 -3635.267)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_357" data-name="グループ 357">
              <circle id="楕円形_2488" data-name="楕円形 2488" cx="425.406" cy="425.406" r="425.406" transform="translate(184.796 -3643.609)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_358" data-name="グループ 358">
              <circle id="楕円形_2489" data-name="楕円形 2489" cx="433.914" cy="433.914" r="433.914" transform="translate(176.288 -3652.117)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_359" data-name="グループ 359">
              <circle id="楕円形_2490" data-name="楕円形 2490" cx="442.592" cy="442.592" r="442.592" transform="translate(167.61 -3660.795)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_360" data-name="グループ 360">
              <circle id="楕円形_2491" data-name="楕円形 2491" cx="451.444" cy="451.444" r="451.444" transform="translate(158.758 -3669.647)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_361" data-name="グループ 361">
              <circle id="楕円形_2492" data-name="楕円形 2492" cx="460.473" cy="460.473" r="460.473" transform="translate(149.729 -3678.676)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_362" data-name="グループ 362">
              <circle id="楕円形_2493" data-name="楕円形 2493" cx="469.683" cy="469.683" r="469.683" transform="translate(140.52 -3687.885)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_363" data-name="グループ 363">
              <circle id="楕円形_2494" data-name="楕円形 2494" cx="479.076" cy="479.076" r="479.076" transform="translate(131.126 -3697.279)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_364" data-name="グループ 364">
              <circle id="楕円形_2495" data-name="楕円形 2495" cx="488.658" cy="488.658" r="488.658" transform="translate(121.544 -3706.86)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_365" data-name="グループ 365">
              <circle id="楕円形_2496" data-name="楕円形 2496" cx="498.431" cy="498.431" r="498.431" transform="translate(111.771 -3716.634)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_366" data-name="グループ 366">
              <circle id="楕円形_2497" data-name="楕円形 2497" cx="508.4" cy="508.4" r="508.4" transform="translate(101.803 -3726.602)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_367" data-name="グループ 367">
              <circle id="楕円形_2498" data-name="楕円形 2498" cx="518.568" cy="518.568" r="518.568" transform="translate(91.635 -3736.77)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_368" data-name="グループ 368">
              <circle id="楕円形_2499" data-name="楕円形 2499" cx="528.939" cy="528.939" r="528.939" transform="translate(81.263 -3747.142)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_369" data-name="グループ 369">
              <circle id="楕円形_2500" data-name="楕円形 2500" cx="539.518" cy="539.518" r="539.518" transform="translate(70.684 -3757.72)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_370" data-name="グループ 370">
              <circle id="楕円形_2501" data-name="楕円形 2501" cx="550.308" cy="550.308" r="550.308" transform="translate(59.894 -3768.511)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_371" data-name="グループ 371">
              <circle id="楕円形_2502" data-name="楕円形 2502" cx="561.314" cy="561.314" r="561.314" transform="translate(48.888 -3779.517)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_372" data-name="グループ 372">
              <circle id="楕円形_2503" data-name="楕円形 2503" cx="572.54" cy="572.54" r="572.54" transform="translate(37.662 -3790.743)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_373" data-name="グループ 373">
              <circle id="楕円形_2504" data-name="楕円形 2504" cx="583.991" cy="583.991" r="583.991" transform="translate(26.211 -3802.194)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_374" data-name="グループ 374">
              <circle id="楕円形_2505" data-name="楕円形 2505" cx="595.671" cy="595.671" r="595.671" transform="translate(14.531 -3813.874)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_375" data-name="グループ 375">
              <circle id="楕円形_2506" data-name="楕円形 2506" cx="607.584" cy="607.584" r="607.584" transform="translate(2.618 -3825.787)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_376" data-name="グループ 376">
              <circle id="楕円形_2507" data-name="楕円形 2507" cx="619.736" cy="619.736" r="619.736" transform="translate(-9.534 -3837.939)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_377" data-name="グループ 377">
              <circle id="楕円形_2508" data-name="楕円形 2508" cx="632.131" cy="632.131" r="632.131" transform="translate(-21.929 -3850.333)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_378" data-name="グループ 378">
              <circle id="楕円形_2509" data-name="楕円形 2509" cx="644.773" cy="644.773" r="644.773" transform="translate(-34.571 -3862.976)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_379" data-name="グループ 379">
              <circle id="楕円形_2510" data-name="楕円形 2510" cx="657.669" cy="657.669" r="657.669" transform="translate(-47.467 -3875.872)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_380" data-name="グループ 380">
              <circle id="楕円形_2511" data-name="楕円形 2511" cx="670.822" cy="670.822" r="670.822" transform="translate(-60.62 -3889.025)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_381" data-name="グループ 381">
              <circle id="楕円形_2512" data-name="楕円形 2512" cx="684.239" cy="684.239" r="684.239" transform="translate(-74.037 -3902.441)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_382" data-name="グループ 382">
              <circle id="楕円形_2513" data-name="楕円形 2513" cx="697.924" cy="697.924" r="697.924" transform="translate(-87.721 -3916.126)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_383" data-name="グループ 383">
              <circle id="楕円形_2514" data-name="楕円形 2514" cx="711.882" cy="711.882" r="711.882" transform="translate(-101.68 -3930.085)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_384" data-name="グループ 384">
              <circle id="楕円形_2515" data-name="楕円形 2515" cx="726.12" cy="726.12" r="726.12" transform="translate(-115.918 -3944.322)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_385" data-name="グループ 385">
              <circle id="楕円形_2516" data-name="楕円形 2516" cx="740.642" cy="740.642" r="740.642" transform="translate(-130.44 -3958.845)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_386" data-name="グループ 386">
              <circle id="楕円形_2517" data-name="楕円形 2517" cx="755.455" cy="755.455" r="755.455" transform="translate(-145.253 -3973.657)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_387" data-name="グループ 387">
              <circle id="楕円形_2518" data-name="楕円形 2518" cx="770.564" cy="770.564" r="770.564" transform="translate(-160.362 -3988.767)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_388" data-name="グループ 388">
              <circle id="楕円形_2519" data-name="楕円形 2519" cx="785.975" cy="785.975" r="785.975" transform="translate(-175.773 -4004.178)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_389" data-name="グループ 389">
              <circle id="楕円形_2520" data-name="楕円形 2520" cx="801.695" cy="801.695" r="801.695" transform="translate(-191.493 -4019.897)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_390" data-name="グループ 390">
              <circle id="楕円形_2521" data-name="楕円形 2521" cx="817.729" cy="817.729" r="817.729" transform="translate(-207.527 -4035.931)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <g id="グループ_391" data-name="グループ 391">
              <circle id="楕円形_2522" data-name="楕円形 2522" cx="834.083" cy="834.083" r="834.083" transform="translate(-223.881 -4052.286)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  }
}

export default Orb01;