import React from 'react';
import { Context } from './Context.js';
import { Root, BasicHeaders } from './ApiUrl.js';
import { List01, ThumbsGroup, Accordion01 } from './CommonComponent.js';

var url = Root + '/wp-json/wp/v2/original/?per_page=6&_fields=custom,thumb_url,id,title,slug';

class ComponentOriginalList extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { //state初期化
      datas: [],
      id: "",
      thumbShow: [],
      show: false,
    };
    this.contentSlug = "original";
  }

  handleClick() {
    this.context.toggleflagList('no04',!this.context.flagList.no04);
  }

  fetchDatas(){
    fetch(url,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        datas: json,
      },function(){
        this.setState({
          show: true,
        },function(){
          this.context.toggleToppageShow('no04',this.state.show);
        });
      });
      this.setStateList(json);
    });
  }

  setStateList(datas){
    const thumbShowList = datas.map((data) => {
      return false;
    });
    this.setState({ thumbShow: thumbShowList },function(){
      // console.log(this.state)
    });
  }

  changeStateList(index, val) {
    if(this.state.thumbShow){
      var thumbShow_copy = [...this.state.thumbShow]
      thumbShow_copy = thumbShow_copy.slice();
      thumbShow_copy[index] = val;
      this.setState({thumbShow: thumbShow_copy});
    }
  }

  componentDidMount() {
    this.fetchDatas();
  }

  render() {
    return (
        <Accordion01
          show={this.state.show}
          handleClick={this.handleClick.bind(this)}
          expandFlag={this.context.flagList.no04}
          lo={"A"}
          contentSlug={this.contentSlug}
          className={"S_accordion01_ver03"}
          text={{
            ttl : "Original",
            listname : "List",
          }}
          ThumbsGroup={
            <ThumbsGroup
              datas={this.state.datas}
              thumbShow={this.state.thumbShow}
              className={"S_accordion01__wrap04_ver04"}
            />
          }
          List={
            <List01
              contentSlug = {this.contentSlug}
              datas={this.state.datas}
              changeStateList={(index, val) => this.changeStateList(index, val)}
              className={""}
            />
          }
        />
    );
  }
}

export default ComponentOriginalList;