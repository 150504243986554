import React from 'react';
import { Link } from "react-router-dom";
import { Context } from './Context.js';
import Arrow02 from './svg/Arrow02.js';

class ComponentZouList extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { //state初期化
      show: false,
    };
  }

  handleClick() {
    this.context.toggleflagList('no05',!this.context.flagList.no05);
  }

  componentDidMount() {
    this.setState({
      show: true,
    },function(){
      this.context.toggleToppageShow('no05',this.state.show);
    });
  }

  render() {
    return (
      <div
        className="S_accordion01 S_accordion01_ver04"
        data-show={this.state.show}
      >
        <h2 className="S_accordion01__ttl01" onClick={() => { this.handleClick(); }}>
          [ Zou ]
          <span className="S_accordion01__icon01"  aria-expanded={this.context.flagList.no05}>
            <Arrow02 />
          </span>
        </h2>
        <div className="S_accordion01__wrap01" aria-expanded={this.context.flagList.no05}>
          <p className="S_accordion01__txt02 S_accordion01__txt02_ver01"><a href="https://noudo.jp/zou/">View more</a></p>
        </div>
      </div>
    );
  }
}

export default ComponentZouList;