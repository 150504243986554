import React from 'react';
import { Route, Switch } from "react-router-dom";
import ComponentOSDetail from './ComponentOSDetail.js';
import { Root, BasicHeaders } from './ApiUrl.js';
import { FilterSelectBox, List02, TxtConverted, Modal01, Nav01 } from './CommonComponent.js';

var archiveUrl =  Root + '/wp-json/wp/v2/online_store/?per_page=100';
var pageUrl = Root + '/wp-json/wp/v2/pages/95?_fields=custom,custom_img,about';
var cateUrl = Root + '/wp-json/wp/v2/oscat/';

const CateList = (props) => {
  // console.log(props.preCate);
  // console.log(props.itemDates);
  // console.log(props.pageSlug);
  var items;
  if(Object.keys(props.preCate).length){
    items = props.preCate.map((parent) => {
      const items = parent.children.map((child) => {
        return(
          <React.Fragment key={child.id}>
            {((props.value === String(parent.id))||(props.value === 'all')) &&
              <dl className="S_list02" data-cat={parent.id}>
                <dt className="S_list02__txt01">{child.name}</dt>
                <dd>
                  <List02 itemDates={props.itemDates} pageSlug={props.pageSlug} child={child} />
                </dd>
              </dl>
            }
          </React.Fragment>
        )
      })
      return(
          <React.Fragment key={parent.id}>
            {items}
          </React.Fragment>
        )
    });
  }
  return (
    <>{items}</>
  )
}

class PageOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //state初期化
      pageDates: {},
      itemDates: {},
      cateDates: {},
      preCate: {},
      about: "",
      value: "all",
      selected_name: "All",
      pageDatesLoad: false,
      itemDatesLoad: false,
      cateDatesLoad: false,
      pageShow: false,
    };
    this.pageSlug = 'online_store';
  }

  fetchPage(){
    fetch(pageUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        pageDates: json,
        about: json.custom.about,
      },function(){
        this.setState({
          pageDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.cateDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  fetchCate(){
    fetch(cateUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        cateDates: json,
      },function(){
        this.setState({
          preCate: this.catePrettify(),
          cateDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.cateDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  fetchItem(){
    fetch(archiveUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        itemDates: json,
      },function(){
        this.setState({
          itemDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.cateDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  catePrettify(){
    var data = {...this.state.cateDates};
    // console.log(data);
    var parents = [];
    var child = [];
    for(var i in data){
      if(data[i].parent===0){
        parents.push(data[i]);
      }else{
        child.push(data[i]);
      }
    }
    for(var j in parents){
      parents[j].children = [];
      for(var k in child){
        if(parents[j].id===child[k].parent){
          parents[j].children.push(child[k])
        }
      }
    }
    return parents;
  }

  hundleOnClick = (event) => {
    this.setState({
      value: event.target.value,
      selected_name: event.target.name,
    });
  }

  componentDidMount() {
    this.fetchPage();
    this.fetchItem();
    this.fetchCate();
  }

  render() {
    // console.log(this.state)
    return (
          <Modal01
            pageShow = {this.state.pageShow}
            area01={
              <Nav01 
                ttl={"Online Store"}
                link={[
                        {
                          href: "about",
                          name: "About"
                        },
                        {
                          href: "archive",
                          name: "Item List"
                        },
                      ]}
              />
            }
            area02={
              <>
                <div id="about" className="S_lo06 S_section01">
                  <h3 className="S_heading03">　</h3>
                  <p
                    className="S_lo06__txt01"
                    dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.about) }}
                  />
                </div>
                <div id="archive" className="S_lo01 S_section01">
                  <h3 className="S_heading03">Item List</h3>
                  <div className="S_lo01__wrap01">
                    <div className="S_lo01__wrap02">
                      <div className="S_lo01__wrap04">
                        <p>Category</p>
                        <FilterSelectBox
                          preCate={this.state.preCate}
                          value={this.state.value}
                          hundleOnClick={this.hundleOnClick}
                          selected_name={this.state.selected_name}
                        />
                      </div>
                      <div className="S_lo01__wrap05">
                        <CateList
                          preCate={this.state.preCate}
                          itemDates={this.state.itemDates} 
                          pageSlug={this.pageSlug}
                          value={this.state.value}
                        />
                      </div>
                    </div>
                    <div className="S_lo01__wrap03">
                      <Switch>
                        <Route path={"/"+this.pageSlug+"/:slug/"} component={ComponentOSDetail} />
                      </Switch>
                    </div>
                  </div>
                </div>
              </>
            }
          />
    );
  }
}

export default PageOS;