// import logo from './logo.svg';
import React from 'react';
import { Route, Switch, useLocation, } from "react-router-dom";
// import EventListener from 'react-event-listener';
import { gsap } from 'gsap'
// import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import './App.css';
import { Context } from './Context.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTopPage from './PageTopPage.js';
import PageYoutube from './PageYoutube.js';
import PageProduce from './PageProduce.js';
import PageOS from './PageOS.js';
import PageOriginal from './PageOriginal.js';
import PageProfile from './PageProfile.js';
import PageAbout from './PageAbout.js';
import { Page404 } from './Page404.js';
import Circle01 from './svg/Circle01.js';
import Circle02 from './svg/Circle02.js';
import Circle03 from './svg/Circle03.js';
import Circle04 from './svg/Circle04.js';
import Circle05 from './svg/Circle05.js';
import Circle06 from './svg/Circle06.js';
import Circle07 from './svg/Circle07.js';
import Circle08 from './svg/Circle08.js';
import Circle09 from './svg/Circle09.js';
import Orb01 from './svg/Orb01.js';
import Orb02 from './svg/Orb02.js';
import Orb03 from './svg/Orb03.js';
import Orb04 from './svg/Orb04.js';
import Orb05 from './svg/Orb05.js';
import './css/style.css';

const MainRoute = () => {
  // const nodeRef = useRef();
  const location = useLocation();
  // console.log(nodeRef);
  var path = location.pathname;
  var dir = path.substring(0, path.lastIndexOf("/"));
  // console.log(path);
  return(
    <TransitionGroup className="S_MainRoute">
      <CSSTransition key={dir} classNames={"is"} timeout={200} >
        <Switch location={location} >
          <Route exact path="/about/" component = {PageAbout} />
          <Route exact path="/profile/" component = {PageProfile} />
          <Route path="/youtube/" component = {PageYoutube} />
          <Route path="/produce/" component = {PageProduce} />
          <Route exact path="/online_store/" component = {PageOS} />
          <Route path="/online_store/:slug/" component = {PageOS} />
          <Route path="/original/" component = {PageOriginal} />
          <Route exact path="/" render={() => <PageTopPage />} />
          <Route component = {Page404} />
        </Switch>
       </CSSTransition>
     </TransitionGroup>
    )
}

class App extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.toggleflagList = (childProp,val) => {
      const flagList_copy = { ...this.state.flagList };
      flagList_copy[childProp] = val;
      this.setState(state => ({
        flagList: flagList_copy,
      }),function(){
        this.setState(state => ({
          orb04Flag: state.flagList.no01 && state.flagList.no02 && state.flagList.no03,
          orb05Flag: state.flagList.no01 && state.flagList.no02 && state.flagList.no03 && state.flagList.no04,
        }))
      });
    };
    this.toggleToppageShow = (childProp,val) => {
      const show_copy = { ...this.state.show };
      show_copy[childProp] = val;
      // console.log(show_copy);
      this.setState(state => ({
        show: show_copy,
      }),function(){
        var flag = true;
        for(var i in show_copy){
          flag = flag && show_copy[i];
        }
        this.setState(state => ({
          toppageShow: flag,
        }))
      });
    };
    this.setStateFalse = () => {
      this.setState({
        show: {
          no01: false,
          no02: false,
          no03: false,
          no04: false,
          no05: false,
        },
        toppageShow: false,
      })
    }
    this.state = { //state初期化
      orb01: false,
      orb04Flag: false,
      orb05Flag: false, 
      flagList: {
        no01: false,
        no02: false,
        no03: false,
        no04: false,
        no05: false,
      },
      toggleflagList: this.toggleflagList,
      show: {
        no01: false,
        no02: false,
        no03: false,
        no04: false,
        no05: false,
      },
      toppageShow: false,
      toggleToppageShow: this.toggleToppageShow,
      setStateFalse: this.setStateFalse,
    };
  }

  updateState(state) {
    this.setState(state);
    this.setState(state => ({
      orb04Flag: state.orb01 && state.orb02 && state.orb03,
      orb05Flag: state.orb01 && state.orb02 && state.orb03 && state.orb04,
    }),function(){
      // console.log(this.state);
    })
  }
  
  componentDidMount() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
  }

  componentDidUpdate(prevProps,prevState) {
    // console.log(this.state.toppageShow);
  }

  render() {
    return (
      <Context.Provider
        value={this.state}>
        <div className="S_rootInner">
          <MainRoute />
          <div className="S_bg">
            <Circle01 />
            <Circle02 />
            <Circle03 />
            <Circle04 />
            <Circle05 />
            <Circle06 />
            <Circle07 />
            <Circle08 />
            <Circle09 />
            <Orb01 orb01 ={this.state.flagList.no01} />
            <Orb02 orb02 ={this.state.flagList.no02} />
            <Orb03 orb03 ={this.state.flagList.no03} />
            <Orb04 orb04 ={this.state.flagList.no04} />
            <Orb05 orb05 ={this.state.flagList.no05} />
          </div>
        </div>
      </Context.Provider>
    );
  } 
}

export default App;