import React from 'react';
import { Route, Switch } from "react-router-dom";
import ComponentYoutubeDetail from './ComponentYoutubeDetail.js';
import { Root, BasicHeaders } from './ApiUrl.js';
// import { WPAPI } from 'wpapi';
import { List02, TxtConverted, Modal01, Nav01 } from './CommonComponent.js';

// var WPAPI = require( 'wpapi' );
// const wp = new WPAPI({
//     endpoint: 'http://1234:works@1037noudo.pkpweb2.i234.me/wp/wp-json',
//     username: 'admin',
//     password: '1234'
// });

// const posts = wp.pages().id(135).then((posts) => {
//   console.log(posts);
// });

var archiveUrl =  Root + '/wp-json/wp/v2/youtube/?per_page=100';
var pageUrl = Root + '/wp-json/wp/v2/pages/28?_fields=custom,custom_img';

const ListMember = (props) => {
  var pageDates = {...props.pageDates};
  if(Object.keys(props.pageDates).length){
    var name = pageDates.custom.name;
    var txt = pageDates.custom.txt;
    var img = pageDates.custom_img;
    var list = [];
    for(var i in txt){
      txt[i] = TxtConverted(txt[i]);
      list.push(
          <li className="S_list01__item01" key={i}>
            <img src={img[i]} alt="" />
            <dl>
              <dt className="S_list01__txt01">{name[i]}</dt>
              <dd>
                <p className="S_list01__txt02" dangerouslySetInnerHTML={{ __html: txt[i] }} />
              </dd>
            </dl>
          </li>
        )
      }
  }
  return(
    <ul className="S_list01">{list}</ul>
    )
}

class PageYoutube extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //state初期化
      pageDates: {},
      itemDates: {},
      about: "",
      pageDatesLoad: false,
      itemDatesLoad: false,
      pageShow: false,
    };
    this.pageSlug = 'youtube';
  }

  fetchPage(){
    fetch(pageUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        pageDates: json,
        about: json.custom.about,
      },function(){
        this.setState({
          pageDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  fetchItem(){
    fetch(archiveUrl,{
      method:'GET',
      headers: BasicHeaders,
    },{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        itemDates: json,
      },function(){
        this.setState({
          itemDatesLoad: true,
        },function(){
          this.setState(state => ({
            pageShow: state.itemDatesLoad && state.pageDatesLoad,
          }))
        })
      });
    });
  }

  componentDidMount() {
    this.fetchPage();
    this.fetchItem();
  }

  render() {
    return (
          <Modal01
            pageShow = {this.state.pageShow}
            area01={
              <Nav01 
                ttl={"YouTube"}
                link={[
                        {
                          href: "about",
                          name: "About"
                        },
                        {
                          href: "archive",
                          name: "Archive"
                        },
                        {
                          href: "member",
                          name: "Member"
                        },
                      ]}
              />
            }
            area02={
              <>
                <div id="about" className="S_lo06 S_section01">
                  <h3 className="S_heading03">　</h3>
                  <p
                    className="S_lo06__txt01"
                    dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.about) }}
                  />
                </div>
                <div id="archive" className="S_lo01 S_section01">
                  <h3 className="S_heading03">Archive</h3>
                  <div className="S_lo01__wrap01 S_lo01__wrap01_ver01">
                    <div className="S_lo01__wrap02">
                      <div className="S_lo01__wrap05">
                        <List02 itemDates={this.state.itemDates} pageSlug={this.pageSlug} />
                      </div>
                    </div>
                    <div className="S_lo01__wrap03">
                      <Switch>
                        <Route path={"/"+this.pageSlug+"/:slug/"} component={ComponentYoutubeDetail} />
                      </Switch>
                    </div>
                  </div>
                </div>
                <div id="member" className="S_section01 S_section01_ver01">
                  <h3 className="S_heading03">Member</h3>
                  <ListMember
                    pageDates={this.state.pageDates}
                  />
                </div>
              </>
            }
          />
    );
  }
}

export default PageYoutube;