import React, {useRef, useMemo, useEffect} from 'react';
import { Root, BasicHeaders } from './ApiUrl.js';
import Cross01 from './svg/Cross01.js';
import { ListImgs, TxtConverted } from './CommonComponent.js';

var url = Root + '/wp-json/wp/v2/online_store/';

const ScriptReExe = (props) => {
  // console.log(props.htmlString.length);
  const divRef = useRef();

  var scriptStrings;

  if(props.htmlString.length>0){
    scriptStrings = props.htmlString.match(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    );
  }

  const initialHTMLString = useMemo(() => {
    if(props.htmlString.length>0){
      const scriptStrings = props.htmlString.match(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
      );
      // console.log(scriptStrings)
      
      if(scriptStrings){
        return scriptStrings.reduce((acc, current) => {
          return acc.replace(current, '');
        }, props.htmlString);
      }else{
        return false;
      }
    }
  }, [props.htmlString]);

  const fragment = document
        .createRange()
        .createContextualFragment(scriptStrings);

  const fragment02 = document
        .createRange()
        .createContextualFragment(initialHTMLString);

  useEffect(() => {
      if(fragment02){
        if(divRef.current){
          divRef.current.innerHTML = '';
        }
        // console.log(htmldivRef.current)
        divRef.current.appendChild(fragment02);
        // console.log("html追加")
      }
    }, []);

  useEffect(() => {
      if(fragment){
        divRef.current.appendChild(fragment);
        // console.log("スクリプトの追加実行")
      }
    }, [props.currentSlug]);

  if(props.htmlString.length>0){
    return (
      <React.Fragment>
        <div
          ref={divRef}
        />
      </React.Fragment>
    )
  }else{
    return false;
  }
}

const ListCart = (props) => {
  if(props.cart_code){
    var list = [];
    for(var i in props.cart_code){
      list.push(
        <li className="S_list03__item01" key={i} >
          <dl>
            {props.cart_name[i].length>0 && <dt>{props.cart_name[i]}</dt>}
            <dd>
              {(() => {
                console.log(props)
                if (props.cart_url[i].length>0) {
                  return <a className="S_lo05__btn01" targe="_blank" href={props.cart_url[i]}>BUY</a>
                } else if(props.cart_code[i].length>0) {
                  return <ScriptReExe
                          htmlString={props.cart_code[i]}
                          currentSlug={props.currentSlug}
                        />
                }else{
                  return false;
                }
              })()}
            </dd>
          </dl>
        </li>
      );
    }
  }
  return(
    <ul className="S_list03">{list}</ul>
    )
}

class ComponentOSDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //state初期化
      id: "",
      title: "",
      img: "",
      kind: "",
      title_jp: "",
      price: "",
      txt: "",
      img_pc: "",
      img_sp: "",
      cart_code: [],
      cart_name: [],
      cart_url: [],
      embed: "",
      currentSlug: "",
      pageShow: false,
    };
    // console.log(this.props)
  }

  fetchDetailData(){
    this.setState({
      pageShow: false,
    })
    var params = this.props.match.params;
    if(params.slug===undefined){
      return null;
    }
    else{
      fetch(url+'?slug='+params.slug,{
      method:'GET',
      headers: BasicHeaders,
    })
      .then(response => {
          return response.json();
      })
      .then(json => {
        // console.log(json[0]);
        this.setState({
          id: json[0].id,
          title: json[0].title.rendered,
          img: json[0].custom_img_main,
          kind: json[0].custom.kind,
          title_jp: json[0].custom.title_jp,
          price: json[0].custom.price,
          cart_name: json[0].custom.name,
          cart_code: json[0].custom.code,
          cart_url: json[0].custom.url,
          txt: json[0].custom.txt,
          img_pc: json[0].custom_img_pc,
          img_sp: json[0].custom_img_sp,
          embed: json[0].custom.embed,
        },function(){
          this.setState({
            pageShow: true,
          })
        });
      });
    }
  }

  removeDomAddedByStoresJs(){
    var elm = document.getElementById('storesjp-button');
    if(elm){
      document.getElementById('storesjp-button').remove();
      elm = "";
      console.log("js消した")
    }
    var elm01 = document.getElementById('storesjp-button-cart');
    if(elm01){
       document.getElementById('storesjp-button-cart').remove();
       elm01 = "";
       console.log("モーダル消した")
    }
  }

  componentDidMount() {
    this.fetchDetailData();
    // console.log("componentDidMount");
    this.setState({currentSlug: this.props.match.params.slug},function(){
      // console.log(this.state.currentSlug);
    })
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate");
    // console.log(this.props.match.params.id);
    
    if(prevProps.match.url !== this.props.match.url) {
      this.removeDomAddedByStoresJs();
      this.fetchDetailData();
      this.setState({
        currentSlug: this.props.match.params.slug,
        cart_code: "",
      },function(){
        // console.log(this.state.currentSlug);
      })
    }
  }

  componentWillUnmount() {
    this.removeDomAddedByStoresJs();
  }

  render() {
    // console.log(this.state)
    return (
          <div className="S_lo05" data-show={this.state.pageShow}>
            <div className="S_lo05__wrap04">
              <div className="S_modal__btn01" onClick={() => this.props.history.goBack()}>
                <Cross01 />
              </div>
            </div>
            <div className="S_lo05__wrap01">
              {this.state.img &&
                <div className="S_lo05__wrap02">
                  <img src={this.state.img} alt="" />
                </div>
              }
              <div className="S_lo05__wrap03">
                {this.state.kind &&
                  <p className="S_lo05__txt01">{this.state.kind}</p>
                }
                {this.state.title &&
                 <h4 className="S_lo05__txt02">{this.state.title}</h4>
                }
                {this.state.title_jp &&
                 <p className="S_lo05__txt03">{this.state.title_jp}</p>
                }
                {this.state.price &&
                 <p className="S_lo05__txt04">{this.state.price}</p>
                }
                {this.state.cart_code &&
                  <ListCart
                    cart_code={this.state.cart_code}
                    cart_name={this.state.cart_name}
                    cart_url={this.state.cart_url}
                    currentSlug={this.state.currentSlug}
                  />
                }
              </div>
            </div>
            {this.state.txt &&
              <p className="S_lo05__txt05" dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.txt) }} />
            }
            {this.state.img_pc &&
              <ListImgs
                imgs={this.state.img_pc}
                className={"S_lo05__list01 u_d_none_SP"}
              />
            }
            {this.state.img_sp &&
              <ListImgs
                imgs={this.state.img_sp}
                className={"S_lo05__list01 u_d_none_PC"}
              />
            }
            {this.state.embed &&
              <div className="S_lo05__wrap05">
                <div
                  className="S_iframe01"
                  dangerouslySetInnerHTML={{ __html: this.state.embed }}
                />
              </div>
            }
          </div>
    );
  }
}

export default ComponentOSDetail;