import React from 'react';
import { Link } from "react-router-dom";
import ComponentYoutubeList from './ComponentYoutubeList.js';
import ComponentOSList from './ComponentOSList.js';
import ComponentOriginalList from './ComponentOriginalList.js';
import ComponentProduceList from './ComponentProduceList.js';
import ComponentZouList from './ComponentZouList.js';
import Logo01 from './svg/Logo01.js';
import Logo02 from './svg/Logo02.js';
import { Context } from './Context.js';
import { Root } from './ApiUrl.js';

class PageTopPage extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { //state初期化
      show: false,
    }
  }

  componentWillUnmount(){
    this.context.setStateFalse();
    // console.log("componentWillUnmount"+this.context.toppageShow);
  }

  render() {
    // console.log(this.context.toppageShow)
    return (
      <div
        className="S_container"
        data-show={this.context.toppageShow}
      >
        <div className="S_App">
          <div className="S_App__wrap01">
            <h1 className="S_heading01">
              <Link to="/"><Logo01 /><Logo02 /></Link>
            </h1>
          </div>
          <div className="S_TopPage">
            <ComponentYoutubeList />
            <ComponentOSList />
            <ComponentProduceList />
            <ComponentOriginalList />
            <ComponentZouList />
          </div>
          <nav className="S_nav03">
            <ul>
              <li><Link to="/about/">About</Link></li>
              <li><Link to="/profile/">Profile</Link></li>
              <li><a href={`${Root}/contact/`} target="_blank" rel="noreferrer">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default PageTopPage;