import React from 'react';
import { Root, BasicHeaders } from './ApiUrl.js';
import { Modal02 } from './CommonComponent.js';
import Logo01 from './svg/Logo01.js';

var pageUrl = Root + '/wp-json/wp/v2/pages/36?_fields=custom,content';

class PageAbout extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //state初期化
      editer: "",
      about_txt: "",
    };
  }

  componentDidMount() {
    fetch(pageUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      console.log(json)
      this.setState({
        editer: json.content.rendered,
        about_txt: json.custom.about_txt,
      });
      // console.log(this.state)
    });
  }

  render() {
    return (
      <Modal02>
        <div className="S_lo03">
          <div className="S_lo03__wrap02">
            <Logo01 />
          </div>
          <div className="S_lo07" dangerouslySetInnerHTML={{ __html: this.state.editer }} />
        </div>
      </Modal02>
    );
  }
}

export default PageAbout;