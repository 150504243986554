import React from 'react';
import { gsap } from 'gsap'
import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

class Orb05 extends React.Component {
  constructor(props){
    super(props);
    this.tl05 = new TimelineLite();
  }

  componentDidMount() {
    this.tl05
    .to("#orb05", {
      opacity: 0.5,
      motionPath:{
        path: "#circle02",
        align: "#circle02",
        autoRotate: 62,
        alignOrigin: [0.5, 0.5],
        start: 0.65,
        end: 0.65,
      }
    })
    .pause("#orb05", {
      // opacity: 0.5,
    })
    .to("#orb05", {
      opacity: 1,
      duration: 0.5,
    })
    .to("#orb05", {
      duration: 40, 
      repeat: -1,
      yoyo: false,
      ease: "none",
      motionPath:{
        path: "#circle02",
        align: "#circle02",
        autoRotate: 62,
        alignOrigin: [0.5, 0.5],
        start: 0.65,
        end: 1.65,
      }
    });
  }

  componentDidUpdate() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
    if(this.props.orb05){
      this.tl05.play()
    }else(
      this.tl05.pause()
    )
  }

  componentWillUnmount() {
    // console.log("unmount");
    // this.props.display(false);
  }

  render() {
     return (
       <svg id="orb05" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="80.594" height="80.594" viewBox="0 0 80.594 80.594">
         <defs>
           <clipPath id="orb05_CP">
             <circle id="楕円形_3002" data-name="楕円形 3002" cx="40.297" cy="40.297" r="40.297" transform="translate(443.77 -4936.94)" fill="none"/>
           </clipPath>
         </defs>
         <g id="グループ_408" data-name="グループ 408" transform="translate(-443.77 4936.94)" clipPath="url(#orb05_CP)">
           <g id="グループ_407" data-name="グループ 407">
             <circle id="楕円形_2885" data-name="楕円形 2885" cx="85" cy="85" r="85" transform="translate(525.454 -4917.896)" fill="#e33c0b" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2886" data-name="楕円形 2886" cx="86.7" cy="86.7" r="86.7" transform="translate(523.754 -4919.597)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2887" data-name="楕円形 2887" cx="88.434" cy="88.434" r="88.434" transform="translate(522.02 -4921.331)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2888" data-name="楕円形 2888" cx="90.203" cy="90.203" r="90.203" transform="translate(520.252 -4923.099)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2889" data-name="楕円形 2889" cx="92.007" cy="92.007" r="92.007" transform="translate(518.448 -4924.903)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2890" data-name="楕円形 2890" cx="93.847" cy="93.847" r="93.847" transform="translate(516.607 -4926.744)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2891" data-name="楕円形 2891" cx="95.724" cy="95.724" r="95.724" transform="translate(514.73 -4928.621)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2892" data-name="楕円形 2892" cx="97.638" cy="97.638" r="97.638" transform="translate(512.816 -4930.535)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2893" data-name="楕円形 2893" cx="99.591" cy="99.591" r="99.591" transform="translate(510.863 -4932.488)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2894" data-name="楕円形 2894" cx="101.583" cy="101.583" r="101.583" transform="translate(508.871 -4934.479)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2895" data-name="楕円形 2895" cx="103.615" cy="103.615" r="103.615" transform="translate(506.84 -4936.511)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2896" data-name="楕円形 2896" cx="105.687" cy="105.687" r="105.687" transform="translate(504.767 -4938.583)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2897" data-name="楕円形 2897" cx="107.801" cy="107.801" r="107.801" transform="translate(502.654 -4940.697)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2898" data-name="楕円形 2898" cx="109.957" cy="109.957" r="109.957" transform="translate(500.498 -4942.853)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2899" data-name="楕円形 2899" cx="112.156" cy="112.156" r="112.156" transform="translate(498.299 -4945.052)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2900" data-name="楕円形 2900" cx="114.399" cy="114.399" r="114.399" transform="translate(496.055 -4947.295)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2901" data-name="楕円形 2901" cx="116.687" cy="116.687" r="116.687" transform="translate(493.768 -4949.583)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2902" data-name="楕円形 2902" cx="119.021" cy="119.021" r="119.021" transform="translate(491.434 -4951.917)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2903" data-name="楕円形 2903" cx="121.401" cy="121.401" r="121.401" transform="translate(489.053 -4954.297)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2904" data-name="楕円形 2904" cx="123.829" cy="123.829" r="123.829" transform="translate(486.625 -4956.726)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2905" data-name="楕円形 2905" cx="126.306" cy="126.306" r="126.306" transform="translate(484.149 -4959.202)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2906" data-name="楕円形 2906" cx="128.832" cy="128.832" r="128.832" transform="translate(481.623 -4961.728)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2907" data-name="楕円形 2907" cx="131.408" cy="131.408" r="131.408" transform="translate(479.046 -4964.305)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2908" data-name="楕円形 2908" cx="134.036" cy="134.036" r="134.036" transform="translate(476.418 -4966.933)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2909" data-name="楕円形 2909" cx="136.717" cy="136.717" r="136.717" transform="translate(473.737 -4969.614)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2910" data-name="楕円形 2910" cx="139.452" cy="139.452" r="139.452" transform="translate(471.003 -4972.348)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2911" data-name="楕円形 2911" cx="142.241" cy="142.241" r="142.241" transform="translate(468.214 -4975.137)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2912" data-name="楕円形 2912" cx="145.085" cy="145.085" r="145.085" transform="translate(465.369 -4977.982)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2913" data-name="楕円形 2913" cx="147.987" cy="147.987" r="147.987" transform="translate(462.467 -4980.884)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2914" data-name="楕円形 2914" cx="150.947" cy="150.947" r="150.947" transform="translate(459.508 -4983.843)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2915" data-name="楕円形 2915" cx="153.966" cy="153.966" r="153.966" transform="translate(456.489 -4986.862)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2916" data-name="楕円形 2916" cx="157.045" cy="157.045" r="157.045" transform="translate(453.409 -4989.941)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2917" data-name="楕円形 2917" cx="160.186" cy="160.186" r="160.186" transform="translate(450.268 -4993.083)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2918" data-name="楕円形 2918" cx="163.39" cy="163.39" r="163.39" transform="translate(447.065 -4996.286)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2919" data-name="楕円形 2919" cx="166.657" cy="166.657" r="166.657" transform="translate(443.797 -4999.554)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2920" data-name="楕円形 2920" cx="169.991" cy="169.991" r="169.991" transform="translate(440.464 -5002.887)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2921" data-name="楕円形 2921" cx="173.39" cy="173.39" r="173.39" transform="translate(437.064 -5006.287)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2922" data-name="楕円形 2922" cx="176.858" cy="176.858" r="176.858" transform="translate(433.596 -5009.755)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2923" data-name="楕円形 2923" cx="180.395" cy="180.395" r="180.395" transform="translate(430.059 -5013.292)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2924" data-name="楕円形 2924" cx="184.003" cy="184.003" r="184.003" transform="translate(426.451 -5016.9)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2925" data-name="楕円形 2925" cx="187.683" cy="187.683" r="187.683" transform="translate(422.771 -5020.58)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2926" data-name="楕円形 2926" cx="191.437" cy="191.437" r="191.437" transform="translate(419.017 -5024.333)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2927" data-name="楕円形 2927" cx="195.266" cy="195.266" r="195.266" transform="translate(415.189 -5028.162)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2928" data-name="楕円形 2928" cx="199.171" cy="199.171" r="199.171" transform="translate(411.283 -5032.068)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2929" data-name="楕円形 2929" cx="203.155" cy="203.155" r="203.155" transform="translate(407.3 -5036.051)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2930" data-name="楕円形 2930" cx="207.218" cy="207.218" r="207.218" transform="translate(403.237 -5040.114)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2931" data-name="楕円形 2931" cx="211.362" cy="211.362" r="211.362" transform="translate(399.092 -5044.258)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2932" data-name="楕円形 2932" cx="215.589" cy="215.589" r="215.589" transform="translate(394.865 -5048.486)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2933" data-name="楕円形 2933" cx="219.901" cy="219.901" r="219.901" transform="translate(390.553 -5052.797)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2934" data-name="楕円形 2934" cx="224.299" cy="224.299" r="224.299" transform="translate(386.155 -5057.196)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2935" data-name="楕円形 2935" cx="228.785" cy="228.785" r="228.785" transform="translate(381.669 -5061.682)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2936" data-name="楕円形 2936" cx="233.361" cy="233.361" r="233.361" transform="translate(377.094 -5066.257)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2937" data-name="楕円形 2937" cx="238.028" cy="238.028" r="238.028" transform="translate(372.426 -5070.924)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2938" data-name="楕円形 2938" cx="242.788" cy="242.788" r="242.788" transform="translate(367.666 -5075.685)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2939" data-name="楕円形 2939" cx="247.644" cy="247.644" r="247.644" transform="translate(362.81 -5080.541)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2940" data-name="楕円形 2940" cx="252.597" cy="252.597" r="252.597" transform="translate(357.857 -5085.494)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2941" data-name="楕円形 2941" cx="257.649" cy="257.649" r="257.649" transform="translate(352.805 -5090.545)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2942" data-name="楕円形 2942" cx="262.802" cy="262.802" r="262.802" transform="translate(347.652 -5095.699)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2943" data-name="楕円形 2943" cx="268.058" cy="268.058" r="268.058" transform="translate(342.396 -5100.955)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2944" data-name="楕円形 2944" cx="273.419" cy="273.419" r="273.419" transform="translate(337.035 -5106.316)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2945" data-name="楕円形 2945" cx="278.888" cy="278.888" r="278.888" transform="translate(331.567 -5111.784)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2946" data-name="楕円形 2946" cx="284.465" cy="284.465" r="284.465" transform="translate(325.989 -5117.362)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2947" data-name="楕円形 2947" cx="290.155" cy="290.155" r="290.155" transform="translate(320.3 -5123.051)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2948" data-name="楕円形 2948" cx="295.958" cy="295.958" r="295.958" transform="translate(314.497 -5128.854)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2949" data-name="楕円形 2949" cx="301.877" cy="301.877" r="301.877" transform="translate(308.577 -5134.773)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2950" data-name="楕円形 2950" cx="307.914" cy="307.914" r="307.914" transform="translate(302.54 -5140.811)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2951" data-name="楕円形 2951" cx="314.073" cy="314.073" r="314.073" transform="translate(296.382 -5146.969)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2952" data-name="楕円形 2952" cx="320.354" cy="320.354" r="320.354" transform="translate(290.1 -5153.251)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2953" data-name="楕円形 2953" cx="326.761" cy="326.761" r="326.761" transform="translate(283.693 -5159.658)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2954" data-name="楕円形 2954" cx="333.297" cy="333.297" r="333.297" transform="translate(277.158 -5166.193)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2955" data-name="楕円形 2955" cx="339.962" cy="339.962" r="339.962" transform="translate(270.492 -5172.859)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2956" data-name="楕円形 2956" cx="346.762" cy="346.762" r="346.762" transform="translate(263.693 -5179.658)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2957" data-name="楕円形 2957" cx="353.697" cy="353.697" r="353.697" transform="translate(256.757 -5186.593)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2958" data-name="楕円形 2958" cx="360.771" cy="360.771" r="360.771" transform="translate(249.683 -5193.667)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2959" data-name="楕円形 2959" cx="367.986" cy="367.986" r="367.986" transform="translate(242.468 -5200.883)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2960" data-name="楕円形 2960" cx="375.346" cy="375.346" r="375.346" transform="translate(235.108 -5208.243)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2961" data-name="楕円形 2961" cx="382.853" cy="382.853" r="382.853" transform="translate(227.601 -5215.75)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2962" data-name="楕円形 2962" cx="390.51" cy="390.51" r="390.51" transform="translate(219.944 -5223.407)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2963" data-name="楕円形 2963" cx="398.32" cy="398.32" r="398.32" transform="translate(212.134 -5231.217)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2964" data-name="楕円形 2964" cx="406.287" cy="406.287" r="406.287" transform="translate(204.168 -5239.183)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2965" data-name="楕円形 2965" cx="414.412" cy="414.412" r="414.412" transform="translate(196.042 -5247.309)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2966" data-name="楕円形 2966" cx="422.701" cy="422.701" r="422.701" transform="translate(187.754 -5255.597)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2967" data-name="楕円形 2967" cx="431.155" cy="431.155" r="431.155" transform="translate(179.3 -5264.051)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2968" data-name="楕円形 2968" cx="439.778" cy="439.778" r="439.778" transform="translate(170.677 -5272.674)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2969" data-name="楕円形 2969" cx="448.573" cy="448.573" r="448.573" transform="translate(161.881 -5281.47)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2970" data-name="楕円形 2970" cx="457.545" cy="457.545" r="457.545" transform="translate(152.91 -5290.441)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2971" data-name="楕円形 2971" cx="466.696" cy="466.696" r="466.696" transform="translate(143.759 -5299.592)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2972" data-name="楕円形 2972" cx="476.03" cy="476.03" r="476.03" transform="translate(134.425 -5308.926)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2973" data-name="楕円形 2973" cx="485.55" cy="485.55" r="485.55" transform="translate(124.904 -5318.447)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2974" data-name="楕円形 2974" cx="495.261" cy="495.261" r="495.261" transform="translate(115.193 -5328.158)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2975" data-name="楕円形 2975" cx="505.166" cy="505.166" r="505.166" transform="translate(105.288 -5338.063)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2976" data-name="楕円形 2976" cx="515.27" cy="515.27" r="515.27" transform="translate(95.185 -5348.166)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2977" data-name="楕円形 2977" cx="525.575" cy="525.575" r="525.575" transform="translate(84.879 -5358.472)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2978" data-name="楕円形 2978" cx="536.087" cy="536.087" r="536.087" transform="translate(74.368 -5368.983)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2979" data-name="楕円形 2979" cx="546.808" cy="546.808" r="546.808" transform="translate(63.646 -5379.705)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2980" data-name="楕円形 2980" cx="557.744" cy="557.744" r="557.744" transform="translate(52.71 -5390.641)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2981" data-name="楕円形 2981" cx="568.899" cy="568.899" r="568.899" transform="translate(41.555 -5401.796)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2982" data-name="楕円形 2982" cx="580.277" cy="580.277" r="580.277" transform="translate(30.177 -5413.174)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2983" data-name="楕円形 2983" cx="591.883" cy="591.883" r="591.883" transform="translate(18.571 -5424.779)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2984" data-name="楕円形 2984" cx="603.721" cy="603.721" r="603.721" transform="translate(6.734 -5436.617)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2985" data-name="楕円形 2985" cx="615.795" cy="615.795" r="615.795" transform="translate(-5.341 -5448.691)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2986" data-name="楕円形 2986" cx="628.111" cy="628.111" r="628.111" transform="translate(-17.657 -5461.007)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2987" data-name="楕円形 2987" cx="640.673" cy="640.673" r="640.673" transform="translate(-30.219 -5473.57)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2988" data-name="楕円形 2988" cx="653.487" cy="653.487" r="653.487" transform="translate(-43.032 -5486.383)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2989" data-name="楕円形 2989" cx="666.556" cy="666.556" r="666.556" transform="translate(-56.102 -5499.453)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2990" data-name="楕円形 2990" cx="679.887" cy="679.887" r="679.887" transform="translate(-69.433 -5512.784)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2991" data-name="楕円形 2991" cx="693.485" cy="693.485" r="693.485" transform="translate(-83.031 -5526.382)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2992" data-name="楕円形 2992" cx="707.355" cy="707.355" r="707.355" transform="translate(-96.901 -5540.251)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2993" data-name="楕円形 2993" cx="721.502" cy="721.502" r="721.502" transform="translate(-111.048 -5554.398)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2994" data-name="楕円形 2994" cx="735.932" cy="735.932" r="735.932" transform="translate(-125.478 -5568.829)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2995" data-name="楕円形 2995" cx="750.651" cy="750.651" r="750.651" transform="translate(-140.196 -5583.547)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2996" data-name="楕円形 2996" cx="765.664" cy="765.664" r="765.664" transform="translate(-155.209 -5598.56)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2997" data-name="楕円形 2997" cx="780.977" cy="780.977" r="780.977" transform="translate(-170.523 -5613.874)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2998" data-name="楕円形 2998" cx="796.596" cy="796.596" r="796.596" transform="translate(-186.142 -5629.493)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_2999" data-name="楕円形 2999" cx="812.528" cy="812.528" r="812.528" transform="translate(-202.074 -5645.425)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_3000" data-name="楕円形 3000" cx="828.779" cy="828.779" r="828.779" transform="translate(-218.325 -5661.675)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
             <circle id="楕円形_3001" data-name="楕円形 3001" cx="845.354" cy="845.354" r="845.354" transform="translate(-234.9 -5678.251)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
           </g>
         </g>
       </svg>
     );
  }
}

export default Orb05;