import React from 'react';

export default function Logo02() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="120.92" height="12.221" viewBox="0 0 120.92 12.221">
  <g id="グループ_491" data-name="グループ 491" transform="translate(0)">
    <path id="パス_21" data-name="パス 21" d="M2.938.9H0V0H6.945V.9H4.006V12.054H2.938Z" fill="#1a1311"/>
    <path id="パス_22" data-name="パス 22" d="M4.742,7.563H4.708a2.706,2.706,0,0,1-2.387,1.2A2.23,2.23,0,0,1,0,6.277C0,3.322,2.972,3.623,4.675,3.489v-.7C4.675,1.553,4.307.8,2.972.8c-.918,0-1.653.451-1.653,1.452H.25C.334.634,1.587,0,3.056,0c.851,0,2.621.084,2.621,2.237v4.24a18.041,18.041,0,0,0,.1,2.121H4.742ZM4.675,4.29c-1.436.05-3.606-.116-3.606,1.937,0,.952.484,1.737,1.519,1.737A2.164,2.164,0,0,0,4.675,5.827Z" transform="translate(9.75 3.456)" fill="#1a1311"/>
    <path id="パス_23" data-name="パス 23" d="M0,1.269V0H1.236V1.269ZM.117,12.054V3.623h1v8.431Z" transform="translate(21.104)" fill="#1a1311"/>
    <path id="パス_24" data-name="パス 24" d="M0,7.613,3.506.8H.2V0H4.524V.835L1.1,7.63H4.608v.8H0Z" transform="translate(27.383 3.623)" fill="#1a1311"/>
    <path id="パス_25" data-name="パス 25" d="M0,5.292V3.472C0,1.285.935,0,2.9,0S5.81,1.285,5.81,3.472v1.82c0,2.187-.935,3.473-2.905,3.473S0,7.479,0,5.292Zm1-.918c0,2.2.25,3.589,1.9,3.589s1.9-1.386,1.9-3.589C4.808,2.187,4.557.8,2.9.8S1,2.187,1,4.374Z" transform="translate(36.617 3.456)" fill="#1a1311"/>
    <path id="パス_26" data-name="パス 26" d="M1.069,0V12.054H0V0Z" transform="translate(57.067)" fill="#1a1311"/>
    <path id="パス_27" data-name="パス 27" d="M0,0H1.068L2.955,7.379h.034L4.775,0H6.01L7.88,7.379h.033L9.8,0h1L8.481,8.431H7.229L5.376,1.219H5.342L3.573,8.431H2.321Z" transform="translate(62.961 3.623)" fill="#1a1311"/>
    <path id="パス_28" data-name="パス 28" d="M4.742,7.563H4.708a2.707,2.707,0,0,1-2.387,1.2A2.231,2.231,0,0,1,0,6.277C0,3.322,2.972,3.623,4.675,3.489v-.7C4.675,1.553,4.308.8,2.972.8c-.919,0-1.653.451-1.653,1.452H.25C.334.634,1.586,0,3.055,0c.851,0,2.621.084,2.621,2.237v4.24a17.981,17.981,0,0,0,.1,2.121H4.742ZM4.675,4.29c-1.436.05-3.606-.116-3.606,1.937,0,.952.485,1.737,1.519,1.737A2.165,2.165,0,0,0,4.675,5.827Z" transform="translate(77.906 3.456)" fill="#1a1311"/>
    <path id="パス_29" data-name="パス 29" d="M2.521,8.765C.7,8.765-.016,7.88,0,6.094H1.018c0,1.152.268,1.92,1.553,1.92A1.31,1.31,0,0,0,4.007,6.528C4.007,4.324.218,4.892.218,2.1.218.551,1.286,0,2.755,0c1.669,0,2.2,1.152,2.2,2.437H3.974C3.924,1.369,3.64.8,2.5.8A1.2,1.2,0,0,0,1.219,2.02c0,2.1,3.79,1.536,3.79,4.357A2.194,2.194,0,0,1,2.521,8.765Z" transform="translate(88.759 3.456)" fill="#1a1311"/>
    <path id="パス_30" data-name="パス 30" d="M4.742,7.563H4.708a2.706,2.706,0,0,1-2.387,1.2A2.231,2.231,0,0,1,0,6.277C0,3.322,2.972,3.623,4.675,3.489v-.7C4.675,1.553,4.307.8,2.972.8c-.918,0-1.653.451-1.653,1.452H.25C.334.634,1.587,0,3.056,0c.851,0,2.621.084,2.621,2.237v4.24a18.021,18.021,0,0,0,.1,2.121H4.742ZM4.675,4.29c-1.436.05-3.606-.116-3.606,1.937,0,.952.484,1.737,1.519,1.737A2.164,2.164,0,0,0,4.675,5.827Z" transform="translate(98.495 3.456)" fill="#1a1311"/>
    <path id="パス_31" data-name="パス 31" d="M0,0H1V7.346h.034l2.9-3.723H5.109L2.12,7.379l3.189,4.675H4.04L1.035,7.463H1v4.591H0Z" transform="translate(109.665)" fill="#1a1311"/>
    <path id="パス_32" data-name="パス 32" d="M0,1.269V0H1.236V1.269ZM.117,12.054V3.623h1v8.431Z" transform="translate(119.684)" fill="#1a1311"/>
  </g>
</svg>
  );
}