import React, {useRef, createRef, useState} from 'react';
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import Arrow01 from './svg/Arrow01.js';
import Arrow02 from './svg/Arrow02.js';
import Cross01 from './svg/Cross01.js';
import Logo01 from './svg/Logo01.js';
import Logo02 from './svg/Logo02.js';
import { Link as SmoothLink } from 'react-scroll';
import { Root } from './ApiUrl.js';

//TOPページリンク一覧用
export const List01 = (props) => {
  var listItems;
  // var parent = useRef([]);
  // props.datas.forEach((_, i) => {
  //   parent.current[i] = React.createRef();
  // });
  // useEffect(() => {
  //   parent.current.forEach((elm) => {
  //     if(elm.current.clientWidth<elm.current.children[0].clientWidth){
  //       console.log(elm.current.clientWidth);
  //       console.log(elm.current.children[0].clientWidth);
  //       console.log("大きい");
  //       console.log(elm);
  //       elm.current.className = elm.current.className + " is-over";
  //       // elm.current.children[0].style.width = elm.current.clientWidth - 14 + "px"
  //     }
  //   });
  // }, [props.show]);
  if(props.contentSlug==="produce"){
    listItems = props.datas.map((data,i) => {
      return(
        <li className={`S_list04__item01 ${props.className}`} key={data.id} 
        >
          <Link
            to={"/" + props.contentSlug + "/" + data.slug}
            onMouseOver={() => props.changeStateList(i,true)}
            onMouseOut={() => props.changeStateList(i,false)}
          >- {TxtCut(data.title.rendered,14)}</Link>
        </li>
        )
      }
    )
  }else{
    listItems = props.datas.map((data,i) => {
      return(
          <li className={`S_list04__item01 ${props.className}`} key={data.id} >
            <Link
              to={"/" + props.contentSlug + "/" + data.slug}
              onMouseOver={() => props.changeStateList(i,true)}
              onMouseOut={() => props.changeStateList(i,false)}
            >- {data.title.rendered}</Link>
          </li>
        )
      }
    )
  }
  return(
    <ul className={`S_list04 ${props.className}`}>{listItems}</ul>
  )
}

//OS下層ページリンク一覧用
export const List02 = (props) => {
  var items;
  if(Object.keys(props.itemDates).length){
    items = props.itemDates.map((data) => {
      if(data.oscat!==undefined){
        if(data.oscat.indexOf(props.child.id)!==-1){
          return(
            <li className="S_list02__item01" key={data.id}>
              <NavLink activeClassName='is-active' to={'/'+props.pageSlug+'/' + data.slug}>{data.title.rendered}</NavLink>
            </li>
          )
        }else{
          return null;
        }
      }else{
        return(
          <li className="S_list02__item01" key={data.id}>
            <NavLink activeClassName='is-active' to={'/'+props.pageSlug+'/' + data.slug}>{data.title.rendered}</NavLink>
          </li>
        )
      }
    });
  }
  return(
    <ul>{items}</ul>
  )
}

// 下層　画像一覧用
export const ListImgs = (props) => {
  if(props.imgs){
    var list = [];
    for(var i in props.imgs){
      list.push(
        <li key={i}><img src={props.imgs[i]} alt="" /></li>
      );
    }
  }
  return(
    <ul className={props.className}>{list}</ul>
    )
}

export const ThumbsGroup = (props) => {
  const nodeRef = useRef([]);
  const thumbs = props.datas.map((data,i) => {
    nodeRef.current[i] = createRef();
    if(data.thumb_url){
      return (
            <React.Fragment key={data.id}>
              <CSSTransition nodeRef={nodeRef.current[i]} in={props.thumbShow[i]} timeout={200} classNames="is">
                <div ref={nodeRef.current[i]} className={`S_accordion01__wrap04 ${props.className}`}>
                  <img
                    className="S_accordion01__item02"
                    src={data.thumb_url[0]}
                    alt=""
                  />
                </div>
              </CSSTransition>
            </React.Fragment>
          )
      }else{
        return null;
        // if(data.custom.embed){
        //   return (
        //         <React.Fragment key={data.id}>
        //           <CSSTransition nodeRef={nodeRef.current[i]} in={props.thumbShow[i]} timeout={200} classNames="is">
        //             <div
        //               ref={nodeRef.current[i]}
        //               className="S_iframe01 S_iframe01_ver01 S_accordion01__item02"
        //               dangerouslySetInnerHTML={{ __html: data.custom.embed }}
        //             />
        //           </CSSTransition>
        //         </React.Fragment>
        //       );
        // }else if(data.custom_img){
        //   return (
        //         <React.Fragment key={data.id}>
        //           <CSSTransition nodeRef={nodeRef.current[i]} in={props.thumbShow[i]} timeout={200} classNames="is">
        //             <img
        //               ref={nodeRef.current[i]}
        //               className="S_accordion01__item02"
        //               src={data.custom_img[0]}
        //               alt=""
        //             />
        //           </CSSTransition>
        //         </React.Fragment>
        //       );
        // }
      }
  });
  return (
    <>
      {thumbs}
    </>
    )
}

export const Accordion01 = (props) => {
  return(
      <div
        className={`S_accordion01 ${props.className}`}
        data-show={props.show}
      >
        {props.ThumbsGroup}
        <h2 className="S_accordion01__ttl01" onClick={() => { props.handleClick(); }}>
          [ {props.text.ttl} ]
          <span className="S_accordion01__icon01" aria-expanded={props.expandFlag}>
            <Arrow02 />
          </span>
        </h2>
        {props.lo === "A" &&
          <div className="S_accordion01__wrap01" aria-expanded={props.expandFlag}>
            <p className="S_accordion01__txt01">
             {props.text.listname}
            </p>
            <div className="S_accordion01__wrap03" style={props.wrap03Style}>
              {props.List}
            </div>
            <p className="S_accordion01__txt02"><Link to={"/"+props.contentSlug}>View more</Link></p>
          </div>
        }
        {props.lo === "B" &&
          <div className="S_accordion01__wrap01 S_accordion01__wrap01_ver01" aria-expanded={props.expandFlag}>
            <div className="S_accordion01__wrap02">
              <p className="S_accordion01__txt01">
               {props.text.listname}
              </p>
              <p className="S_accordion01__txt02 u_d_none_SP"><Link to={"/"+props.contentSlug+"/"}>View more</Link></p>
            </div>
            <div className="S_accordion01__wrap03 S_accordion01__wrap03_ver01">
              {props.List}
            </div>
            <p className="S_accordion01__txt02 u_d_none_PC"><Link to={"/"+props.contentSlug+"/"}>View more</Link></p>
          </div>
        }
      </div>
    )
}

export const FilterSelectBox = (props) => {
  const [show, setShow] = useState(false);
    var cateData = {...props.preCate}
    cateData = Object.values(cateData);
    cateData.unshift({id: "all", name: "All"})
    
    var options = [];
    for(var i in cateData){
      options.push(
        <button className="S_selectbox01__item01" key={cateData[i].id} value={cateData[i].id} name={cateData[i].name} onClick={props.hundleOnClick} >{cateData[i].name}</button>
        )
    }
    return (
        <React.Fragment>
          <div className="S_selectbox01" onClick={() => setShow(!show)}>
            <div className="S_selectbox01__item02" data-direction={show}>
              <Arrow01 />
            </div>
            {show
              ? <div className="S_selectbox01__wrap01">{options}</div>
              : <div className="S_selectbox01__wrap01"><button className="S_selectbox01__item01">{props.selected_name}</button></div>
            }
          </div>
        </React.Fragment>
      )
  }

export const TxtConverted = (text) => {
  if(text){
    return text.toString().replace(/\r?\n/g, '<br>');
  }else{
    return null;
  }
}

export const TxtCut = (text, len) => {
  var length = 0;
  var i;
  var array = [];
  var newTxt;
  for (i = 0; i < text.length; i++) {
    // console.log(text[i])
    if(text[i].match(/[ -~]/) ) {
      length += 0.5;
      // console.log("半角")
    }
    else {
      length += 1;
      // console.log("全角")
    }
    array.push(text[i]);
    if(length>=len){
      array.push("...");
      newTxt = array.join("");
      return newTxt
    }
  }
  // console.log(length);
  newTxt = array.join("");
  return newTxt;
}

export const Modal01 = (props) =>{
  return(
        <div className="S_modal">
          <div className="S_modal__wrap01" data-show={props.pageShow}>
            <Link className="S_modal__btn01" to={"/"}>
              <Cross01 />
            </Link>
            <div className="S_modal__wrap04" id="scrollContainer">
              <div className="S_modal__area01">
                {props.area01}
              </div>
              <div className="S_modal__area02">
                {props.area02}
              </div>
            </div>
          </div>
        </div>
    )
}

export const Modal02 = (props) =>{
  return(
        <div className="S_modal">
          <div className="S_modal__wrap01">
            <Link className="S_modal__btn01" to={"/"}>
              <Cross01 />
            </Link>
            <div className="S_modal__wrap04" id="scrollContainer">
              <h1 className="S_heading01 S_heading01_ver01">
                <Link to="/"><Logo01 /><Logo02 /></Link>
              </h1>
              <div className="S_modal__wrap03">
                <div className="S_modal__area04">
                  <nav className="S_nav02">
                    <ul>
                      <li><NavLink activeClassName="is-active" to="/about/">About</NavLink></li>
                      <li><NavLink activeClassName="is-active" to="/profile/">Profile</NavLink></li>
                      <li><a href={`${Root}/contact/`} target="_blank" rel="noreferrer">Contact</a></li>
                    </ul>
                  </nav>
                </div>
                <div className="S_modal__area05">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export const Nav01 = (props) => {
  var link = props.link.map(data => {
    return (
      <li key={data.href}>
        <SmoothLink
          to={data.href}
          smooth={true}
          duration={800}
          containerId="scrollContainer"
        >{data.name}</SmoothLink>
      </li>
      )
  })
  return(
        <div className="S_lo02">
          <h2 className="S_heading02 S_lo02__ttl01">[ {props.ttl} ]</h2>
          <nav className="S_nav01 S_lo02__wrap01">
            <ul>{link}</ul>
          </nav>
          <h1 className="S_lo02__item01"><Link to="/"><Logo01 /></Link></h1>
        </div>
    )
}