import React from 'react';

export default function Cross01() {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.124" height="10.223" viewBox="0 0 10.124 10.223">
          <g id="グループ_487" data-name="グループ 487" transform="translate(-179.144 -80.148)">
            <line id="線_73" data-name="線 73" x2="9.413" y2="9.52" transform="translate(179.5 80.5)" fill="none" stroke="#707070" strokeWidth="1"/>
            <line id="線_74" data-name="線 74" x1="9.413" y2="9.52" transform="translate(179.5 80.5)" fill="none" stroke="#707070" strokeWidth="1"/>
          </g>
        </svg>
  );
}