import React from 'react';
import { gsap } from 'gsap'
import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

class Orb02 extends React.Component {
  constructor(props){
    super(props);
    this.tl02 = new TimelineLite();
  }

  componentDidMount() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
    this.tl02
    .from("#orb02", {
      opacity: 0,
      duration: 0, 
      motionPath:{
        path: "#circle05",
        align: "#circle05",
        // autoRotate: 89,
        alignOrigin: [0.5, 0.5],
        start: 0.5,
        end: 0.5,
      }
    })
    .pause("#orb02", {
      opacity: 0.5,
    })
    .from("#orb02", {
      opacity: 0.5,
      duration: 0.5,
    })
    .to("#orb02", {
      duration: 40, 
      repeat: -1,
      yoyo: false,
      ease: "none",
      reversed: true,
      motionPath:{
        path: "#circle05",
        align: "#circle05",
        autoRotate: 89,
        alignOrigin: [0.5, 0.5],
        start: 0.5,
        end: 1.5,
      }
    });
  }

  componentDidUpdate() {
    // console.log(this.props)
    if(this.props.orb02){
      this.tl02.play()
    }else(
      this.tl02.pause()
    )
  }

  componentWillUnmount() {
    // console.log("unmount");
    // this.props.display(false);
  }

  render() {
  return (
    <svg id="orb02" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="184.57" height="184.57" viewBox="0 0 184.57 184.57">
      <defs>
        <clipPath id="orb02_CP">
          <circle id="楕円形_2757" data-name="楕円形 2757" cx="92.285" cy="92.285" r="92.285" transform="translate(162.329 -3319.104)" fill="none"/>
        </clipPath>
      </defs>
      <g id="グループ_400" data-name="グループ 400" transform="translate(-162.329 3319.104)">
        <g id="グループ_399" data-name="グループ 399" clipPath="url(#orb02_CP)">
          <g id="グループ_398" data-name="グループ 398">
            <circle id="楕円形_2641" data-name="楕円形 2641" cx="88" cy="88" r="88" transform="translate(522.202 -3306.203)" fill="#6a0f20" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2642" data-name="楕円形 2642" cx="89.76" cy="89.76" r="89.76" transform="translate(520.442 -3307.963)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2643" data-name="楕円形 2643" cx="91.555" cy="91.555" r="91.555" transform="translate(518.647 -3309.758)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2644" data-name="楕円形 2644" cx="93.386" cy="93.386" r="93.386" transform="translate(516.816 -3311.589)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2645" data-name="楕円形 2645" cx="95.254" cy="95.254" r="95.254" transform="translate(514.948 -3313.457)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2646" data-name="楕円形 2646" cx="97.159" cy="97.159" r="97.159" transform="translate(513.043 -3315.362)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2647" data-name="楕円形 2647" cx="99.102" cy="99.102" r="99.102" transform="translate(511.1 -3317.305)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2648" data-name="楕円形 2648" cx="101.084" cy="101.084" r="101.084" transform="translate(509.118 -3319.287)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2649" data-name="楕円形 2649" cx="103.106" cy="103.106" r="103.106" transform="translate(507.096 -3321.309)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2650" data-name="楕円形 2650" cx="105.168" cy="105.168" r="105.168" transform="translate(505.034 -3323.371)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2651" data-name="楕円形 2651" cx="107.272" cy="107.272" r="107.272" transform="translate(502.931 -3325.474)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2652" data-name="楕円形 2652" cx="109.417" cy="109.417" r="109.417" transform="translate(500.785 -3327.62)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2653" data-name="楕円形 2653" cx="111.605" cy="111.605" r="111.605" transform="translate(498.597 -3329.808)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2654" data-name="楕円形 2654" cx="113.837" cy="113.837" r="113.837" transform="translate(496.365 -3332.04)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2655" data-name="楕円形 2655" cx="116.114" cy="116.114" r="116.114" transform="translate(494.088 -3334.317)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2656" data-name="楕円形 2656" cx="118.436" cy="118.436" r="118.436" transform="translate(491.766 -3336.639)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2657" data-name="楕円形 2657" cx="120.805" cy="120.805" r="120.805" transform="translate(489.397 -3339.008)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2658" data-name="楕円形 2658" cx="123.221" cy="123.221" r="123.221" transform="translate(486.981 -3341.424)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2659" data-name="楕円形 2659" cx="125.686" cy="125.686" r="125.686" transform="translate(484.516 -3343.888)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2660" data-name="楕円形 2660" cx="128.199" cy="128.199" r="128.199" transform="translate(482.003 -3346.402)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2661" data-name="楕円形 2661" cx="130.763" cy="130.763" r="130.763" transform="translate(479.439 -3348.966)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2662" data-name="楕円形 2662" cx="133.379" cy="133.379" r="133.379" transform="translate(476.823 -3351.581)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2663" data-name="楕円形 2663" cx="136.046" cy="136.046" r="136.046" transform="translate(474.156 -3354.249)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2664" data-name="楕円形 2664" cx="138.767" cy="138.767" r="138.767" transform="translate(471.435 -3356.97)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2665" data-name="楕円形 2665" cx="141.542" cy="141.542" r="141.542" transform="translate(468.66 -3359.745)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2666" data-name="楕円形 2666" cx="144.373" cy="144.373" r="144.373" transform="translate(465.829 -3362.576)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2667" data-name="楕円形 2667" cx="147.261" cy="147.261" r="147.261" transform="translate(462.941 -3365.463)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2668" data-name="楕円形 2668" cx="150.206" cy="150.206" r="150.206" transform="translate(459.996 -3368.409)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2669" data-name="楕円形 2669" cx="153.21" cy="153.21" r="153.21" transform="translate(456.992 -3371.413)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2670" data-name="楕円形 2670" cx="156.274" cy="156.274" r="156.274" transform="translate(453.928 -3374.477)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2671" data-name="楕円形 2671" cx="159.4" cy="159.4" r="159.4" transform="translate(450.802 -3377.603)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2672" data-name="楕円形 2672" cx="162.588" cy="162.588" r="162.588" transform="translate(447.614 -3380.791)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2673" data-name="楕円形 2673" cx="165.84" cy="165.84" r="165.84" transform="translate(444.363 -3384.042)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2674" data-name="楕円形 2674" cx="169.156" cy="169.156" r="169.156" transform="translate(441.046 -3387.359)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2675" data-name="楕円形 2675" cx="172.539" cy="172.539" r="172.539" transform="translate(437.663 -3390.742)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2676" data-name="楕円形 2676" cx="175.99" cy="175.99" r="175.99" transform="translate(434.212 -3394.193)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2677" data-name="楕円形 2677" cx="179.51" cy="179.51" r="179.51" transform="translate(430.692 -3397.713)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2678" data-name="楕円形 2678" cx="183.1" cy="183.1" r="183.1" transform="translate(427.102 -3401.303)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2679" data-name="楕円形 2679" cx="186.762" cy="186.762" r="186.762" transform="translate(423.44 -3404.965)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2680" data-name="楕円形 2680" cx="190.498" cy="190.498" r="190.498" transform="translate(419.705 -3408.7)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2681" data-name="楕円形 2681" cx="194.307" cy="194.307" r="194.307" transform="translate(415.895 -3412.51)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2682" data-name="楕円形 2682" cx="198.194" cy="198.194" r="198.194" transform="translate(412.008 -3416.396)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2683" data-name="楕円形 2683" cx="202.158" cy="202.158" r="202.158" transform="translate(408.045 -3420.36)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2684" data-name="楕円形 2684" cx="206.201" cy="206.201" r="206.201" transform="translate(404.001 -3424.403)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2685" data-name="楕円形 2685" cx="210.325" cy="210.325" r="210.325" transform="translate(399.877 -3428.527)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2686" data-name="楕円形 2686" cx="214.531" cy="214.531" r="214.531" transform="translate(395.671 -3432.734)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2687" data-name="楕円形 2687" cx="218.822" cy="218.822" r="218.822" transform="translate(391.38 -3437.024)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2688" data-name="楕円形 2688" cx="223.198" cy="223.198" r="223.198" transform="translate(387.004 -3441.401)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2689" data-name="楕円形 2689" cx="227.662" cy="227.662" r="227.662" transform="translate(382.54 -3445.865)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2690" data-name="楕円形 2690" cx="232.215" cy="232.215" r="232.215" transform="translate(377.987 -3450.418)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2691" data-name="楕円形 2691" cx="236.86" cy="236.86" r="236.86" transform="translate(373.342 -3455.063)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2692" data-name="楕円形 2692" cx="241.597" cy="241.597" r="241.597" transform="translate(368.605 -3459.8)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2693" data-name="楕円形 2693" cx="246.429" cy="246.429" r="246.429" transform="translate(363.773 -3464.632)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2694" data-name="楕円形 2694" cx="251.357" cy="251.357" r="251.357" transform="translate(358.845 -3469.56)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2695" data-name="楕円形 2695" cx="256.385" cy="256.385" r="256.385" transform="translate(353.817 -3474.587)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2696" data-name="楕円形 2696" cx="261.512" cy="261.512" r="261.512" transform="translate(348.69 -3479.715)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2697" data-name="楕円形 2697" cx="266.743" cy="266.743" r="266.743" transform="translate(343.46 -3484.945)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2698" data-name="楕円形 2698" cx="272.077" cy="272.077" r="272.077" transform="translate(338.125 -3490.28)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2699" data-name="楕円形 2699" cx="277.519" cy="277.519" r="277.519" transform="translate(332.683 -3495.722)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2700" data-name="楕円形 2700" cx="283.069" cy="283.069" r="283.069" transform="translate(327.133 -3501.272)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2701" data-name="楕円形 2701" cx="288.731" cy="288.731" r="288.731" transform="translate(321.471 -3506.933)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2702" data-name="楕円形 2702" cx="294.505" cy="294.505" r="294.505" transform="translate(315.697 -3512.708)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2703" data-name="楕円形 2703" cx="300.395" cy="300.395" r="300.395" transform="translate(309.807 -3518.598)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2704" data-name="楕円形 2704" cx="306.403" cy="306.403" r="306.403" transform="translate(303.799 -3524.606)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2705" data-name="楕円形 2705" cx="312.531" cy="312.531" r="312.531" transform="translate(297.671 -3530.734)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2706" data-name="楕円形 2706" cx="318.782" cy="318.782" r="318.782" transform="translate(291.42 -3536.985)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2707" data-name="楕円形 2707" cx="325.158" cy="325.158" r="325.158" transform="translate(285.044 -3543.36)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2708" data-name="楕円形 2708" cx="331.661" cy="331.661" r="331.661" transform="translate(278.541 -3549.864)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2709" data-name="楕円形 2709" cx="338.294" cy="338.294" r="338.294" transform="translate(271.908 -3556.497)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2710" data-name="楕円形 2710" cx="345.06" cy="345.06" r="345.06" transform="translate(265.142 -3563.263)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2711" data-name="楕円形 2711" cx="351.961" cy="351.961" r="351.961" transform="translate(258.241 -3570.164)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2712" data-name="楕円形 2712" cx="359" cy="359" r="359" transform="translate(251.202 -3577.203)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2713" data-name="楕円形 2713" cx="366.18" cy="366.18" r="366.18" transform="translate(244.022 -3584.383)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2714" data-name="楕円形 2714" cx="373.504" cy="373.504" r="373.504" transform="translate(236.698 -3591.707)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2715" data-name="楕円形 2715" cx="380.974" cy="380.974" r="380.974" transform="translate(229.228 -3599.177)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2716" data-name="楕円形 2716" cx="388.594" cy="388.594" r="388.594" transform="translate(221.609 -3606.796)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2717" data-name="楕円形 2717" cx="396.365" cy="396.365" r="396.365" transform="translate(213.837 -3614.568)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2718" data-name="楕円形 2718" cx="404.293" cy="404.293" r="404.293" transform="translate(205.909 -3622.495)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2719" data-name="楕円形 2719" cx="412.379" cy="412.379" r="412.379" transform="translate(197.824 -3630.581)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2720" data-name="楕円形 2720" cx="420.626" cy="420.626" r="420.626" transform="translate(189.576 -3638.829)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2721" data-name="楕円形 2721" cx="429.039" cy="429.039" r="429.039" transform="translate(181.163 -3647.241)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2722" data-name="楕円形 2722" cx="437.619" cy="437.619" r="437.619" transform="translate(172.583 -3655.822)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2723" data-name="楕円形 2723" cx="446.372" cy="446.372" r="446.372" transform="translate(163.83 -3664.574)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2724" data-name="楕円形 2724" cx="455.299" cy="455.299" r="455.299" transform="translate(154.903 -3673.502)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2725" data-name="楕円形 2725" cx="464.405" cy="464.405" r="464.405" transform="translate(145.797 -3682.608)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2726" data-name="楕円形 2726" cx="473.693" cy="473.693" r="473.693" transform="translate(136.509 -3691.896)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2727" data-name="楕円形 2727" cx="483.167" cy="483.167" r="483.167" transform="translate(127.035 -3701.37)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2728" data-name="楕円形 2728" cx="492.831" cy="492.831" r="492.831" transform="translate(117.372 -3711.033)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2729" data-name="楕円形 2729" cx="502.687" cy="502.687" r="502.687" transform="translate(107.515 -3720.89)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2730" data-name="楕円形 2730" cx="512.741" cy="512.741" r="512.741" transform="translate(97.461 -3730.944)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2731" data-name="楕円形 2731" cx="522.996" cy="522.996" r="522.996" transform="translate(87.206 -3741.198)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2732" data-name="楕円形 2732" cx="533.456" cy="533.456" r="533.456" transform="translate(76.746 -3751.658)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2733" data-name="楕円形 2733" cx="544.125" cy="544.125" r="544.125" transform="translate(66.077 -3762.327)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2734" data-name="楕円形 2734" cx="555.007" cy="555.007" r="555.007" transform="translate(55.195 -3773.21)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2735" data-name="楕円形 2735" cx="566.107" cy="566.107" r="566.107" transform="translate(44.095 -3784.31)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2736" data-name="楕円形 2736" cx="577.43" cy="577.43" r="577.43" transform="translate(32.773 -3795.632)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2737" data-name="楕円形 2737" cx="588.978" cy="588.978" r="588.978" transform="translate(21.224 -3807.181)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2738" data-name="楕円形 2738" cx="600.758" cy="600.758" r="600.758" transform="translate(9.444 -3818.96)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2739" data-name="楕円形 2739" cx="612.773" cy="612.773" r="612.773" transform="translate(-2.571 -3830.976)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2740" data-name="楕円形 2740" cx="625.028" cy="625.028" r="625.028" transform="translate(-14.826 -3843.231)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2741" data-name="楕円形 2741" cx="637.529" cy="637.529" r="637.529" transform="translate(-27.327 -3855.731)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2742" data-name="楕円形 2742" cx="650.279" cy="650.279" r="650.279" transform="translate(-40.077 -3868.482)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2743" data-name="楕円形 2743" cx="663.285" cy="663.285" r="663.285" transform="translate(-53.083 -3881.488)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2744" data-name="楕円形 2744" cx="676.551" cy="676.551" r="676.551" transform="translate(-66.349 -3894.753)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2745" data-name="楕円形 2745" cx="690.082" cy="690.082" r="690.082" transform="translate(-79.88 -3908.284)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2746" data-name="楕円形 2746" cx="703.883" cy="703.883" r="703.883" transform="translate(-93.681 -3922.086)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2747" data-name="楕円形 2747" cx="717.961" cy="717.961" r="717.961" transform="translate(-107.759 -3936.164)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2748" data-name="楕円形 2748" cx="732.32" cy="732.32" r="732.32" transform="translate(-122.118 -3950.523)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2749" data-name="楕円形 2749" cx="746.967" cy="746.967" r="746.967" transform="translate(-136.765 -3965.169)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2750" data-name="楕円形 2750" cx="761.906" cy="761.906" r="761.906" transform="translate(-151.704 -3980.109)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2751" data-name="楕円形 2751" cx="777.144" cy="777.144" r="777.144" transform="translate(-166.942 -3995.347)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2752" data-name="楕円形 2752" cx="792.687" cy="792.687" r="792.687" transform="translate(-182.485 -4010.89)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2753" data-name="楕円形 2753" cx="808.541" cy="808.541" r="808.541" transform="translate(-198.339 -4026.743)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2754" data-name="楕円形 2754" cx="824.712" cy="824.712" r="824.712" transform="translate(-214.509 -4042.914)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2755" data-name="楕円形 2755" cx="841.206" cy="841.206" r="841.206" transform="translate(-231.004 -4059.408)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2756" data-name="楕円形 2756" cx="858.03" cy="858.03" r="858.03" transform="translate(-247.828 -4076.233)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  );
  }
}

export default Orb02;