import React from 'react';

export default function Logo01() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="22.82" viewBox="0 0 72 22.82">
        <g id="グループ_2" data-name="グループ 2" transform="translate(0 22.82) rotate(-90)">
          <g id="グループ_98" data-name="グループ 98" transform="translate(22.82) rotate(90)">
            <path id="パス_1" data-name="パス 1" d="M0,0H3.337L8.609,15.847V0h2.369V22.186h-2.7L2.369,4.151V22.186H0Z" transform="translate(0 0.317)"/>
            <path id="パス_2" data-name="パス 2" d="M0,17.337V5.451C0,1.934,1.9,0,5.338,0,8.809,0,10.71,1.934,10.71,5.451V17.337c0,3.518-1.9,5.483-5.372,5.483C1.9,22.82,0,20.855,0,17.337Zm8.108.158V5.293c0-2.092-1-3.043-2.769-3.043C3.6,2.25,2.6,3.2,2.6,5.293V17.5c0,2.124,1,3.074,2.736,3.074C7.107,20.569,8.108,19.619,8.108,17.5Z" transform="translate(15.583 0)"/>
            <path id="パス_3" data-name="パス 3" d="M0,17.02V0H2.6V17.21c0,2.092.968,3.042,2.7,3.042s2.7-.95,2.7-3.042V0h2.536V17.02c0,3.518-1.835,5.483-5.271,5.483C1.8,22.5,0,20.538,0,17.02Z" transform="translate(30.732 0.317)"/>
            <path id="パス_4" data-name="パス 4" d="M0,0H5.339c3.6,0,5.272,1.807,5.272,5.388V16.8c0,3.581-1.669,5.388-5.272,5.388H0ZM5.272,19.936c1.835,0,2.736-.856,2.736-3.044V5.26c0-2.186-.9-3.011-2.736-3.011H2.6V19.936Z" transform="translate(45.98 0.317)"/>
            <path id="パス_5" data-name="パス 5" d="M0,17.337V5.451C0,1.934,1.9,0,5.339,0c3.469,0,5.372,1.934,5.372,5.451V17.337c0,3.518-1.9,5.483-5.372,5.483C1.9,22.82,0,20.855,0,17.337Zm8.108.158V5.293c0-2.092-1-3.043-2.769-3.043C3.6,2.25,2.6,3.2,2.6,5.293V17.5c0,2.124,1,3.074,2.736,3.074C7.107,20.569,8.108,19.619,8.108,17.5Z" transform="translate(60.928 0)"/>
          </g>
        </g>
      </svg>
  );
}