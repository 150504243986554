import React from 'react';
import { gsap } from 'gsap'
import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

class Orb03 extends React.Component {
  constructor(props){
    super(props);
    this.tl03 = new TimelineLite();
  }

  componentDidMount() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
    this.tl03
    .to("#orb03", {
      opacity: 0.5,
      motionPath:{
        path: "#circle04",
        align: "#circle04",
        autoRotate: -32,
        alignOrigin: [0.5, 0.55],
        start: 0.83,
        end: 0.83,
      }
    })
    .pause("#orb03", {
      // opacity: 0.5,
    })
    .to("#orb03", {
      opacity: 1,
      duration: 0.5,
    })
    .to("#orb03", {
      duration: 40, 
      repeat: -1,
      yoyo: false,
      ease: "none",
      motionPath:{
        path: "#circle04",
        align: "#circle04",
        autoRotate: -32,
        alignOrigin: [0.5, 0.55],
        start: 0.83,
        end: 1.83,
      }
    });
  }

  componentDidUpdate() {
    // console.log(this.props)
    if(this.props.orb03){
      this.tl03.play()
    }else(
      this.tl03.pause()
    )
  }

  componentWillUnmount() {
    // console.log("unmount");
    // this.props.display(false);
  }

  render() {
  return (
    <svg id="orb03" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="141.992" height="141.992" viewBox="0 0 141.992 141.992">
      <defs>
        <clipPath id="orb03_CP">
          <circle id="楕円形_2640" data-name="楕円形 2640" cx="70.996" cy="70.996" r="70.996" transform="translate(694.157 -3547.198)" fill="none"/>
        </clipPath>
      </defs>
      <g id="グループ_397" data-name="グループ 397" transform="translate(-694.157 3547.198)">
        <g id="グループ_396" data-name="グループ 396" clipPath="url(#orb03_CP)">
          <g id="グループ_395" data-name="グループ 395">
            <circle id="楕円形_2524" data-name="楕円形 2524" cx="87" cy="87" r="87" transform="translate(523.202 -3305.203)" fill="#b40019" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2525" data-name="楕円形 2525" cx="88.74" cy="88.74" r="88.74" transform="translate(521.462 -3306.943)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2526" data-name="楕円形 2526" cx="90.515" cy="90.515" r="90.515" transform="translate(519.687 -3308.718)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2527" data-name="楕円形 2527" cx="92.325" cy="92.325" r="92.325" transform="translate(517.877 -3310.528)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2528" data-name="楕円形 2528" cx="94.172" cy="94.172" r="94.172" transform="translate(516.03 -3312.374)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2529" data-name="楕円形 2529" cx="96.055" cy="96.055" r="96.055" transform="translate(514.147 -3314.258)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2530" data-name="楕円形 2530" cx="97.976" cy="97.976" r="97.976" transform="translate(512.226 -3316.179)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2531" data-name="楕円形 2531" cx="99.936" cy="99.936" r="99.936" transform="translate(510.266 -3318.138)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2532" data-name="楕円形 2532" cx="101.934" cy="101.934" r="101.934" transform="translate(508.268 -3320.137)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2533" data-name="楕円形 2533" cx="103.973" cy="103.973" r="103.973" transform="translate(506.229 -3322.176)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2534" data-name="楕円形 2534" cx="106.053" cy="106.053" r="106.053" transform="translate(504.15 -3324.255)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2535" data-name="楕円形 2535" cx="108.174" cy="108.174" r="108.174" transform="translate(502.029 -3326.376)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2536" data-name="楕円形 2536" cx="110.337" cy="110.337" r="110.337" transform="translate(499.865 -3328.54)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2537" data-name="楕円形 2537" cx="112.544" cy="112.544" r="112.544" transform="translate(497.658 -3330.746)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2538" data-name="楕円形 2538" cx="114.795" cy="114.795" r="114.795" transform="translate(495.407 -3332.997)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2539" data-name="楕円形 2539" cx="117.091" cy="117.091" r="117.091" transform="translate(493.112 -3335.293)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2540" data-name="楕円形 2540" cx="119.432" cy="119.432" r="119.432" transform="translate(490.77 -3337.635)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2541" data-name="楕円形 2541" cx="121.821" cy="121.821" r="121.821" transform="translate(488.381 -3340.024)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2542" data-name="楕円形 2542" cx="124.257" cy="124.257" r="124.257" transform="translate(485.945 -3342.46)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2543" data-name="楕円形 2543" cx="126.743" cy="126.743" r="126.743" transform="translate(483.46 -3344.945)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2544" data-name="楕円形 2544" cx="129.277" cy="129.277" r="129.277" transform="translate(480.925 -3347.48)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2545" data-name="楕円形 2545" cx="131.863" cy="131.863" r="131.863" transform="translate(478.339 -3350.066)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2546" data-name="楕円形 2546" cx="134.5" cy="134.5" r="134.5" transform="translate(475.702 -3352.703)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2547" data-name="楕円形 2547" cx="137.19" cy="137.19" r="137.19" transform="translate(473.012 -3355.393)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2548" data-name="楕円形 2548" cx="139.934" cy="139.934" r="139.934" transform="translate(470.268 -3358.137)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2549" data-name="楕円形 2549" cx="142.733" cy="142.733" r="142.733" transform="translate(467.469 -3360.935)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2550" data-name="楕円形 2550" cx="145.587" cy="145.587" r="145.587" transform="translate(464.615 -3363.79)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2551" data-name="楕円形 2551" cx="148.499" cy="148.499" r="148.499" transform="translate(461.703 -3366.702)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2552" data-name="楕円形 2552" cx="151.469" cy="151.469" r="151.469" transform="translate(458.733 -3369.672)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2553" data-name="楕円形 2553" cx="154.498" cy="154.498" r="154.498" transform="translate(455.704 -3372.701)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2554" data-name="楕円形 2554" cx="157.588" cy="157.588" r="157.588" transform="translate(452.614 -3375.791)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2555" data-name="楕円形 2555" cx="160.74" cy="160.74" r="160.74" transform="translate(449.462 -3378.943)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2556" data-name="楕円形 2556" cx="163.955" cy="163.955" r="163.955" transform="translate(446.247 -3382.158)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2557" data-name="楕円形 2557" cx="167.234" cy="167.234" r="167.234" transform="translate(442.968 -3385.437)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2558" data-name="楕円形 2558" cx="170.579" cy="170.579" r="170.579" transform="translate(439.623 -3388.781)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2559" data-name="楕円形 2559" cx="173.99" cy="173.99" r="173.99" transform="translate(436.212 -3392.193)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2560" data-name="楕円形 2560" cx="177.47" cy="177.47" r="177.47" transform="translate(432.732 -3395.673)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2561" data-name="楕円形 2561" cx="181.02" cy="181.02" r="181.02" transform="translate(429.182 -3399.222)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2562" data-name="楕円形 2562" cx="184.64" cy="184.64" r="184.64" transform="translate(425.562 -3402.843)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2563" data-name="楕円形 2563" cx="188.333" cy="188.333" r="188.333" transform="translate(421.869 -3406.535)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2564" data-name="楕円形 2564" cx="192.099" cy="192.099" r="192.099" transform="translate(418.103 -3410.302)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2565" data-name="楕円形 2565" cx="195.941" cy="195.941" r="195.941" transform="translate(414.261 -3414.144)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2566" data-name="楕円形 2566" cx="199.86" cy="199.86" r="199.86" transform="translate(410.342 -3418.063)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2567" data-name="楕円形 2567" cx="203.857" cy="203.857" r="203.857" transform="translate(406.345 -3422.06)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2568" data-name="楕円形 2568" cx="207.935" cy="207.935" r="207.935" transform="translate(402.267 -3426.137)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2569" data-name="楕円形 2569" cx="212.093" cy="212.093" r="212.093" transform="translate(398.109 -3430.296)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2570" data-name="楕円形 2570" cx="216.335" cy="216.335" r="216.335" transform="translate(393.867 -3434.538)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2571" data-name="楕円形 2571" cx="220.662" cy="220.662" r="220.662" transform="translate(389.54 -3438.865)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2572" data-name="楕円形 2572" cx="225.075" cy="225.075" r="225.075" transform="translate(385.127 -3443.278)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2573" data-name="楕円形 2573" cx="229.577" cy="229.577" r="229.577" transform="translate(380.625 -3447.779)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2574" data-name="楕円形 2574" cx="234.168" cy="234.168" r="234.168" transform="translate(376.034 -3452.371)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2575" data-name="楕円形 2575" cx="238.852" cy="238.852" r="238.852" transform="translate(371.351 -3457.054)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2576" data-name="楕円形 2576" cx="243.629" cy="243.629" r="243.629" transform="translate(366.574 -3461.831)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2577" data-name="楕円形 2577" cx="248.501" cy="248.501" r="248.501" transform="translate(361.701 -3466.704)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2578" data-name="楕円形 2578" cx="253.471" cy="253.471" r="253.471" transform="translate(356.731 -3471.674)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2579" data-name="楕円形 2579" cx="258.541" cy="258.541" r="258.541" transform="translate(351.662 -3476.743)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2580" data-name="楕円形 2580" cx="263.711" cy="263.711" r="263.711" transform="translate(346.491 -3481.914)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2581" data-name="楕円形 2581" cx="268.986" cy="268.986" r="268.986" transform="translate(341.216 -3487.188)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2582" data-name="楕円形 2582" cx="274.365" cy="274.365" r="274.365" transform="translate(335.837 -3492.568)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2583" data-name="楕円形 2583" cx="279.853" cy="279.853" r="279.853" transform="translate(330.349 -3498.055)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2584" data-name="楕円形 2584" cx="285.45" cy="285.45" r="285.45" transform="translate(324.752 -3503.652)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2585" data-name="楕円形 2585" cx="291.159" cy="291.159" r="291.159" transform="translate(319.043 -3509.361)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2586" data-name="楕円形 2586" cx="296.982" cy="296.982" r="296.982" transform="translate(313.22 -3515.185)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2587" data-name="楕円形 2587" cx="302.921" cy="302.921" r="302.921" transform="translate(307.281 -3521.124)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2588" data-name="楕円形 2588" cx="308.98" cy="308.98" r="308.98" transform="translate(301.222 -3527.183)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2589" data-name="楕円形 2589" cx="315.16" cy="315.16" r="315.16" transform="translate(295.043 -3533.362)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2590" data-name="楕円形 2590" cx="321.463" cy="321.463" r="321.463" transform="translate(288.739 -3539.665)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2591" data-name="楕円形 2591" cx="327.892" cy="327.892" r="327.892" transform="translate(282.31 -3546.095)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2592" data-name="楕円形 2592" cx="334.45" cy="334.45" r="334.45" transform="translate(275.752 -3552.652)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2593" data-name="楕円形 2593" cx="341.139" cy="341.139" r="341.139" transform="translate(269.063 -3559.342)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2594" data-name="楕円形 2594" cx="347.962" cy="347.962" r="347.962" transform="translate(262.241 -3566.164)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2595" data-name="楕円形 2595" cx="354.921" cy="354.921" r="354.921" transform="translate(255.281 -3573.124)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2596" data-name="楕円形 2596" cx="362.019" cy="362.019" r="362.019" transform="translate(248.183 -3580.222)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2597" data-name="楕円形 2597" cx="369.26" cy="369.26" r="369.26" transform="translate(240.943 -3587.462)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2598" data-name="楕円形 2598" cx="376.645" cy="376.645" r="376.645" transform="translate(233.557 -3594.847)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2599" data-name="楕円形 2599" cx="384.178" cy="384.178" r="384.178" transform="translate(226.024 -3602.38)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2600" data-name="楕円形 2600" cx="391.861" cy="391.861" r="391.861" transform="translate(218.341 -3610.064)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2601" data-name="楕円形 2601" cx="399.698" cy="399.698" r="399.698" transform="translate(210.504 -3617.901)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2602" data-name="楕円形 2602" cx="407.692" cy="407.692" r="407.692" transform="translate(202.51 -3625.895)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2603" data-name="楕円形 2603" cx="415.846" cy="415.846" r="415.846" transform="translate(194.356 -3634.049)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2604" data-name="楕円形 2604" cx="424.163" cy="424.163" r="424.163" transform="translate(186.039 -3642.366)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2605" data-name="楕円形 2605" cx="432.646" cy="432.646" r="432.646" transform="translate(177.556 -3650.849)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2606" data-name="楕円形 2606" cx="441.299" cy="441.299" r="441.299" transform="translate(168.903 -3659.502)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2607" data-name="楕円形 2607" cx="450.125" cy="450.125" r="450.125" transform="translate(160.077 -3668.328)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2608" data-name="楕円形 2608" cx="459.128" cy="459.128" r="459.128" transform="translate(151.074 -3677.331)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2609" data-name="楕円形 2609" cx="468.31" cy="468.31" r="468.31" transform="translate(141.892 -3686.513)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2610" data-name="楕円形 2610" cx="477.677" cy="477.677" r="477.677" transform="translate(132.525 -3695.879)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2611" data-name="楕円形 2611" cx="487.23" cy="487.23" r="487.23" transform="translate(122.972 -3705.433)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2612" data-name="楕円形 2612" cx="496.975" cy="496.975" r="496.975" transform="translate(113.227 -3715.177)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2613" data-name="楕円形 2613" cx="506.914" cy="506.914" r="506.914" transform="translate(103.288 -3725.117)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2614" data-name="楕円形 2614" cx="517.053" cy="517.053" r="517.053" transform="translate(93.149 -3735.255)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2615" data-name="楕円形 2615" cx="527.394" cy="527.394" r="527.394" transform="translate(82.808 -3745.596)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2616" data-name="楕円形 2616" cx="537.942" cy="537.942" r="537.942" transform="translate(72.261 -3756.144)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2617" data-name="楕円形 2617" cx="548.7" cy="548.7" r="548.7" transform="translate(61.502 -3766.903)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2618" data-name="楕円形 2618" cx="559.674" cy="559.674" r="559.674" transform="translate(50.528 -3777.877)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2619" data-name="楕円形 2619" cx="570.868" cy="570.868" r="570.868" transform="translate(39.334 -3789.071)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2620" data-name="楕円形 2620" cx="582.285" cy="582.285" r="582.285" transform="translate(27.917 -3800.488)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2621" data-name="楕円形 2621" cx="593.931" cy="593.931" r="593.931" transform="translate(16.271 -3812.134)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2622" data-name="楕円形 2622" cx="605.81" cy="605.81" r="605.81" transform="translate(4.393 -3824.012)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2623" data-name="楕円形 2623" cx="617.926" cy="617.926" r="617.926" transform="translate(-7.724 -3836.128)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2624" data-name="楕円形 2624" cx="630.284" cy="630.284" r="630.284" transform="translate(-20.082 -3848.487)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2625" data-name="楕円形 2625" cx="642.89" cy="642.89" r="642.89" transform="translate(-32.688 -3861.093)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2626" data-name="楕円形 2626" cx="655.748" cy="655.748" r="655.748" transform="translate(-45.546 -3873.95)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2627" data-name="楕円形 2627" cx="668.863" cy="668.863" r="668.863" transform="translate(-58.661 -3887.065)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2628" data-name="楕円形 2628" cx="682.24" cy="682.24" r="682.24" transform="translate(-72.038 -3900.443)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2629" data-name="楕円形 2629" cx="695.885" cy="695.885" r="695.885" transform="translate(-85.683 -3914.087)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2630" data-name="楕円形 2630" cx="709.802" cy="709.802" r="709.802" transform="translate(-99.6 -3928.005)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2631" data-name="楕円形 2631" cx="723.998" cy="723.998" r="723.998" transform="translate(-113.796 -3942.201)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2632" data-name="楕円形 2632" cx="738.478" cy="738.478" r="738.478" transform="translate(-128.276 -3956.681)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2633" data-name="楕円形 2633" cx="753.248" cy="753.248" r="753.248" transform="translate(-143.046 -3971.451)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2634" data-name="楕円形 2634" cx="768.313" cy="768.313" r="768.313" transform="translate(-158.111 -3986.516)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2635" data-name="楕円形 2635" cx="783.679" cy="783.679" r="783.679" transform="translate(-173.477 -4001.882)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2636" data-name="楕円形 2636" cx="799.353" cy="799.353" r="799.353" transform="translate(-189.151 -4017.555)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2637" data-name="楕円形 2637" cx="815.34" cy="815.34" r="815.34" transform="translate(-205.138 -4033.542)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2638" data-name="楕円形 2638" cx="831.647" cy="831.647" r="831.647" transform="translate(-221.445 -4049.849)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2639" data-name="楕円形 2639" cx="848.28" cy="848.28" r="848.28" transform="translate(-238.077 -4066.482)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  );
  } 
}

export default Orb03;