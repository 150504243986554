import React from 'react';
import { Root, BasicHeaders } from './ApiUrl.js';
import { TxtConverted, Modal02 } from './CommonComponent.js';

var pageUrl = Root + '/wp-json/wp/v2/pages/32?_fields=custom,custom_img';

class PageProfile extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { //state初期化
      img: "",
      name_jp: "",
      name_en: "",
      txt: "",
      pageShow: false,
    };
    // console.log(this.state)
  }

  componentDidMount() {
    fetch(pageUrl,{
      method:'GET',
      headers: BasicHeaders,
    })
    .then(response => {
        return response.json();
    })
    .then(json => {
      this.setState({
        img: json.custom_img,
        name_jp: json.custom.name_jp,
        name_en: json.custom.name_en,
        txt: json.custom.txt,
        pageShow: true,
      },function(){
        
      });
      // console.log(this.state)
    });
  }

  render() {
    return (
            <Modal02>
              <div className="S_lo03 S_lo03_ver01">
                <img className="S_lo03__img01" src={this.state.img} alt="" />
                <dl>
                  <dt className="S_lo03__wrap01"><span>{this.state.name_jp}</span><span>{this.state.name_en}</span></dt>
                  <dd className="S_lo03__txt01">
                    <p dangerouslySetInnerHTML={{ __html: TxtConverted(this.state.txt) }} />
                  </dd>
                </dl>
              </div>
            </Modal02>
    );
  }
}

export default PageProfile;