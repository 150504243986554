import React from 'react';
import { gsap } from 'gsap'
import { TimelineLite } from "gsap/all";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

class Orb04 extends React.Component {
  constructor(props){
    super(props);
    this.tl04 = new TimelineLite();
  }

  componentDidMount() {
    this.tl04
    .from("#orb04", {
      opacity: 0,
      motionPath:{
        path: "#circle03",
        align: "#circle03",
        autoRotate: 215,
        alignOrigin: [0.5, 0.5],
        start: 0.15,
        end: 0.15,
      }
    })
    .pause("#orb04", {
      // opacity: 0.5,
    })
    .from("#orb04", {
      opacity: 0.5,
      duration: 0.5,
    })
    .to("#orb04", {
      duration: 40, 
      repeat: -1,
      yoyo: false,
      ease: "none",
      reversed: true,
      motionPath:{
        path: "#circle03",
        align: "#circle03",
        autoRotate: 215,
        alignOrigin: [0.5, 0.5],
        start: 0.15,
        end: 1.15,
      }
    });
  }

  componentDidUpdate() {
    gsap.registerPlugin(MotionPathPlugin);
    MotionPathPlugin.convertToPath("circle");
    // console.log(this.props)
    if(this.props.orb04){
      this.tl04.play()
    }else(
      this.tl04.pause()
    )
  }

  componentWillUnmount() {
    // console.log("unmount");
    // this.props.display(false);
  }

  render() {
    return (
      <svg id="orb04" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="113.911" height="113.911" viewBox="0 0 113.911 113.911">
        <defs>
          <clipPath id="orb04_CP">
            <circle id="楕円形_2884" data-name="楕円形 2884" cx="56.956" cy="56.956" r="56.956" transform="translate(683.221 -4705.641)" fill="none"/>
          </clipPath>
        </defs>
        <g id="グループ_405" data-name="グループ 405" transform="translate(-683.221 4705.641)" clipPath="url(#orb04_CP)">
          <g id="グループ_404" data-name="グループ 404">
            <circle id="楕円形_2767" data-name="楕円形 2767" cx="86" cy="86" r="86" transform="translate(524.454 -4918.896)" fill="#df1f1c" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2768" data-name="楕円形 2768" cx="87.72" cy="87.72" r="87.72" transform="translate(522.734 -4920.617)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2769" data-name="楕円形 2769" cx="89.474" cy="89.474" r="89.474" transform="translate(520.98 -4922.371)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2770" data-name="楕円形 2770" cx="91.264" cy="91.264" r="91.264" transform="translate(519.19 -4924.161)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2771" data-name="楕円形 2771" cx="93.089" cy="93.089" r="93.089" transform="translate(517.365 -4925.986)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2772" data-name="楕円形 2772" cx="94.951" cy="94.951" r="94.951" transform="translate(515.503 -4927.848)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2773" data-name="楕円形 2773" cx="96.85" cy="96.85" r="96.85" transform="translate(513.604 -4929.747)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2774" data-name="楕円形 2774" cx="98.787" cy="98.787" r="98.787" transform="translate(511.667 -4931.684)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2775" data-name="楕円形 2775" cx="100.763" cy="100.763" r="100.763" transform="translate(509.692 -4933.659)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2776" data-name="楕円形 2776" cx="102.778" cy="102.778" r="102.778" transform="translate(507.676 -4935.674)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2777" data-name="楕円形 2777" cx="104.834" cy="104.834" r="104.834" transform="translate(505.621 -4937.73)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2778" data-name="楕円形 2778" cx="106.93" cy="106.93" r="106.93" transform="translate(503.524 -4939.827)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2779" data-name="楕円形 2779" cx="109.069" cy="109.069" r="109.069" transform="translate(501.385 -4941.965)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2780" data-name="楕円形 2780" cx="111.25" cy="111.25" r="111.25" transform="translate(499.204 -4944.147)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2781" data-name="楕円形 2781" cx="113.475" cy="113.475" r="113.475" transform="translate(496.979 -4946.372)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2782" data-name="楕円形 2782" cx="115.745" cy="115.745" r="115.745" transform="translate(494.71 -4948.641)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2783" data-name="楕円形 2783" cx="118.06" cy="118.06" r="118.06" transform="translate(492.395 -4950.956)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2784" data-name="楕円形 2784" cx="120.421" cy="120.421" r="120.421" transform="translate(490.034 -4953.317)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2785" data-name="楕円形 2785" cx="122.829" cy="122.829" r="122.829" transform="translate(487.625 -4955.726)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2786" data-name="楕円形 2786" cx="125.286" cy="125.286" r="125.286" transform="translate(485.169 -4958.182)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2787" data-name="楕円形 2787" cx="127.791" cy="127.791" r="127.791" transform="translate(482.663 -4960.688)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2788" data-name="楕円形 2788" cx="130.347" cy="130.347" r="130.347" transform="translate(480.107 -4963.244)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2789" data-name="楕円形 2789" cx="132.954" cy="132.954" r="132.954" transform="translate(477.5 -4965.851)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2790" data-name="楕円形 2790" cx="135.613" cy="135.613" r="135.613" transform="translate(474.841 -4968.51)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2791" data-name="楕円形 2791" cx="138.326" cy="138.326" r="138.326" transform="translate(472.129 -4971.222)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2792" data-name="楕円形 2792" cx="141.092" cy="141.092" r="141.092" transform="translate(469.362 -4973.989)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2793" data-name="楕円形 2793" cx="143.914" cy="143.914" r="143.914" transform="translate(466.54 -4976.811)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2794" data-name="楕円形 2794" cx="146.792" cy="146.792" r="146.792" transform="translate(463.662 -4979.689)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2795" data-name="楕円形 2795" cx="149.728" cy="149.728" r="149.728" transform="translate(460.726 -4982.625)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2796" data-name="楕円形 2796" cx="152.723" cy="152.723" r="152.723" transform="translate(457.732 -4985.619)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2797" data-name="楕円形 2797" cx="155.777" cy="155.777" r="155.777" transform="translate(454.677 -4988.674)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2798" data-name="楕円形 2798" cx="158.893" cy="158.893" r="158.893" transform="translate(451.562 -4991.789)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2799" data-name="楕円形 2799" cx="162.07" cy="162.07" r="162.07" transform="translate(448.384 -4994.967)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2800" data-name="楕円形 2800" cx="165.312" cy="165.312" r="165.312" transform="translate(445.142 -4998.208)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2801" data-name="楕円形 2801" cx="168.618" cy="168.618" r="168.618" transform="translate(441.836 -5001.515)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2802" data-name="楕円形 2802" cx="171.991" cy="171.991" r="171.991" transform="translate(438.464 -5004.887)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2803" data-name="楕円形 2803" cx="175.43" cy="175.43" r="175.43" transform="translate(435.024 -5008.327)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2804" data-name="楕円形 2804" cx="178.939" cy="178.939" r="178.939" transform="translate(431.515 -5011.835)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2805" data-name="楕円形 2805" cx="182.518" cy="182.518" r="182.518" transform="translate(427.937 -5015.414)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2806" data-name="楕円形 2806" cx="186.168" cy="186.168" r="186.168" transform="translate(424.286 -5019.064)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2807" data-name="楕円形 2807" cx="189.891" cy="189.891" r="189.891" transform="translate(420.563 -5022.788)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2808" data-name="楕円形 2808" cx="193.689" cy="193.689" r="193.689" transform="translate(416.765 -5026.586)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2809" data-name="楕円形 2809" cx="197.563" cy="197.563" r="197.563" transform="translate(412.891 -5030.459)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2810" data-name="楕円形 2810" cx="201.514" cy="201.514" r="201.514" transform="translate(408.94 -5034.411)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2811" data-name="楕円形 2811" cx="205.545" cy="205.545" r="205.545" transform="translate(404.91 -5038.441)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2812" data-name="楕円形 2812" cx="209.655" cy="209.655" r="209.655" transform="translate(400.799 -5042.552)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2813" data-name="楕円形 2813" cx="213.849" cy="213.849" r="213.849" transform="translate(396.606 -5046.745)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2814" data-name="楕円形 2814" cx="218.126" cy="218.126" r="218.126" transform="translate(392.329 -5051.022)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2815" data-name="楕円形 2815" cx="222.488" cy="222.488" r="222.488" transform="translate(387.966 -5055.385)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2816" data-name="楕円形 2816" cx="226.938" cy="226.938" r="226.938" transform="translate(383.516 -5059.834)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2817" data-name="楕円形 2817" cx="231.477" cy="231.477" r="231.477" transform="translate(378.978 -5064.373)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2818" data-name="楕円形 2818" cx="236.106" cy="236.106" r="236.106" transform="translate(374.348 -5069.002)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2819" data-name="楕円形 2819" cx="240.828" cy="240.828" r="240.828" transform="translate(369.626 -5073.725)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2820" data-name="楕円形 2820" cx="245.645" cy="245.645" r="245.645" transform="translate(364.81 -5078.542)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2821" data-name="楕円形 2821" cx="250.558" cy="250.558" r="250.558" transform="translate(359.897 -5083.454)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2822" data-name="楕円形 2822" cx="255.569" cy="255.569" r="255.569" transform="translate(354.885 -5088.465)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2823" data-name="楕円形 2823" cx="260.68" cy="260.68" r="260.68" transform="translate(349.774 -5093.577)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2824" data-name="楕円形 2824" cx="265.894" cy="265.894" r="265.894" transform="translate(344.56 -5098.791)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2825" data-name="楕円形 2825" cx="271.212" cy="271.212" r="271.212" transform="translate(339.243 -5104.108)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2826" data-name="楕円形 2826" cx="276.636" cy="276.636" r="276.636" transform="translate(333.818 -5109.533)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2827" data-name="楕円形 2827" cx="282.169" cy="282.169" r="282.169" transform="translate(328.286 -5115.065)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2828" data-name="楕円形 2828" cx="287.812" cy="287.812" r="287.812" transform="translate(322.642 -5120.708)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2829" data-name="楕円形 2829" cx="293.568" cy="293.568" r="293.568" transform="translate(316.886 -5126.465)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2830" data-name="楕円形 2830" cx="299.44" cy="299.44" r="299.44" transform="translate(311.015 -5132.336)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2831" data-name="楕円形 2831" cx="305.428" cy="305.428" r="305.428" transform="translate(305.026 -5138.325)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2832" data-name="楕円形 2832" cx="311.537" cy="311.537" r="311.537" transform="translate(298.917 -5144.434)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2833" data-name="楕円形 2833" cx="317.768" cy="317.768" r="317.768" transform="translate(292.687 -5150.664)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2834" data-name="楕円形 2834" cx="324.123" cy="324.123" r="324.123" transform="translate(286.331 -5157.02)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2835" data-name="楕円形 2835" cx="330.606" cy="330.606" r="330.606" transform="translate(279.849 -5163.502)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2836" data-name="楕円形 2836" cx="337.218" cy="337.218" r="337.218" transform="translate(273.237 -5170.114)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2837" data-name="楕円形 2837" cx="343.962" cy="343.962" r="343.962" transform="translate(266.492 -5176.858)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2838" data-name="楕円形 2838" cx="350.841" cy="350.841" r="350.841" transform="translate(259.613 -5183.738)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2839" data-name="楕円形 2839" cx="357.858" cy="357.858" r="357.858" transform="translate(252.596 -5190.754)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2840" data-name="楕円形 2840" cx="365.015" cy="365.015" r="365.015" transform="translate(245.439 -5197.912)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2841" data-name="楕円形 2841" cx="372.316" cy="372.316" r="372.316" transform="translate(238.139 -5205.212)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2842" data-name="楕円形 2842" cx="379.762" cy="379.762" r="379.762" transform="translate(230.692 -5212.658)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2843" data-name="楕円形 2843" cx="387.357" cy="387.357" r="387.357" transform="translate(223.097 -5220.253)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2844" data-name="楕円形 2844" cx="395.104" cy="395.104" r="395.104" transform="translate(215.35 -5228.001)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2845" data-name="楕円形 2845" cx="403.006" cy="403.006" r="403.006" transform="translate(207.448 -5235.903)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2846" data-name="楕円形 2846" cx="411.066" cy="411.066" r="411.066" transform="translate(199.388 -5243.963)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2847" data-name="楕円形 2847" cx="419.288" cy="419.288" r="419.288" transform="translate(191.167 -5252.185)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2848" data-name="楕円形 2848" cx="427.674" cy="427.674" r="427.674" transform="translate(182.781 -5260.57)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2849" data-name="楕円形 2849" cx="436.227" cy="436.227" r="436.227" transform="translate(174.227 -5269.124)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2850" data-name="楕円形 2850" cx="444.952" cy="444.952" r="444.952" transform="translate(165.503 -5277.848)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2851" data-name="楕円形 2851" cx="453.851" cy="453.851" r="453.851" transform="translate(156.604 -5286.747)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2852" data-name="楕円形 2852" cx="462.928" cy="462.928" r="462.928" transform="translate(147.527 -5295.824)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2853" data-name="楕円形 2853" cx="472.186" cy="472.186" r="472.186" transform="translate(138.268 -5305.083)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2854" data-name="楕円形 2854" cx="481.63" cy="481.63" r="481.63" transform="translate(128.824 -5314.526)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2855" data-name="楕円形 2855" cx="491.262" cy="491.262" r="491.262" transform="translate(119.192 -5324.159)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2856" data-name="楕円形 2856" cx="501.088" cy="501.088" r="501.088" transform="translate(109.367 -5333.984)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2857" data-name="楕円形 2857" cx="511.109" cy="511.109" r="511.109" transform="translate(99.345 -5344.006)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2858" data-name="楕円形 2858" cx="521.332" cy="521.332" r="521.332" transform="translate(89.123 -5354.228)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2859" data-name="楕円形 2859" cx="531.758" cy="531.758" r="531.758" transform="translate(78.696 -5364.655)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2860" data-name="楕円形 2860" cx="542.393" cy="542.393" r="542.393" transform="translate(68.061 -5375.29)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2861" data-name="楕円形 2861" cx="553.241" cy="553.241" r="553.241" transform="translate(57.213 -5386.138)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2862" data-name="楕円形 2862" cx="564.306" cy="564.306" r="564.306" transform="translate(46.148 -5397.203)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2863" data-name="楕円形 2863" cx="575.592" cy="575.592" r="575.592" transform="translate(34.862 -5408.489)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2864" data-name="楕円形 2864" cx="587.104" cy="587.104" r="587.104" transform="translate(23.35 -5420)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2865" data-name="楕円形 2865" cx="598.846" cy="598.846" r="598.846" transform="translate(11.608 -5431.743)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2866" data-name="楕円形 2866" cx="610.823" cy="610.823" r="610.823" transform="translate(-0.369 -5443.72)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2867" data-name="楕円形 2867" cx="623.04" cy="623.04" r="623.04" transform="translate(-12.585 -5455.936)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2868" data-name="楕円形 2868" cx="635.5" cy="635.5" r="635.5" transform="translate(-25.046 -5468.397)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2869" data-name="楕円形 2869" cx="648.21" cy="648.21" r="648.21" transform="translate(-37.756 -5481.107)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2870" data-name="楕円形 2870" cx="661.175" cy="661.175" r="661.175" transform="translate(-50.72 -5494.071)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2871" data-name="楕円形 2871" cx="674.398" cy="674.398" r="674.398" transform="translate(-63.944 -5507.294)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2872" data-name="楕円形 2872" cx="687.886" cy="687.886" r="687.886" transform="translate(-77.432 -5520.783)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2873" data-name="楕円形 2873" cx="701.644" cy="701.644" r="701.644" transform="translate(-91.189 -5534.541)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2874" data-name="楕円形 2874" cx="715.677" cy="715.677" r="715.677" transform="translate(-105.222 -5548.573)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2875" data-name="楕円形 2875" cx="729.99" cy="729.99" r="729.99" transform="translate(-119.536 -5562.887)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2876" data-name="楕円形 2876" cx="744.59" cy="744.59" r="744.59" transform="translate(-134.136 -5577.486)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2877" data-name="楕円形 2877" cx="759.482" cy="759.482" r="759.482" transform="translate(-149.027 -5592.378)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2878" data-name="楕円形 2878" cx="774.671" cy="774.671" r="774.671" transform="translate(-164.217 -5607.568)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2879" data-name="楕円形 2879" cx="790.165" cy="790.165" r="790.165" transform="translate(-179.711 -5623.062)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2880" data-name="楕円形 2880" cx="805.968" cy="805.968" r="805.968" transform="translate(-195.514 -5638.865)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2881" data-name="楕円形 2881" cx="822.087" cy="822.087" r="822.087" transform="translate(-211.633 -5654.984)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2882" data-name="楕円形 2882" cx="838.529" cy="838.529" r="838.529" transform="translate(-228.075 -5671.426)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
            <circle id="楕円形_2883" data-name="楕円形 2883" cx="855.3" cy="855.3" r="855.3" transform="translate(-244.846 -5688.196)" fill="none" stroke="#e28c8f" strokeMiterlimit="10" strokeWidth="1"/>
          </g>
        </g>
      </svg>
    );
  }
}

export default Orb04;